import { VscDash } from "react-icons/vsc";
import { useEffect, useState } from "react";
import styled from "styled-components";

const AadhaarInput = (props) => {
  const inputNo = [1, 2, 3];
  const [value, setValue] = useState({
    field1: "",
    field2: "",
    field3: "",
  });

  useEffect(() => {
    props.handleChange(value);
  }, [value]);

  const handleChange = (e) => {
    const { maxLength, value, name } = e.target;
    const fieldIndex = name.split("-")[1];

    if (value.length >= maxLength) {
      if (parseInt(fieldIndex) < 3) {
        const nextSibling = document.querySelector(
          `input[name=field-${parseInt(fieldIndex) + 1}]`
        );

        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
    // }
    // else if (value.length === 0) {
    //   const nextSibling = document.querySelector(
    //     `input[name=field-${parseInt(fieldIndex) - 1}]`
    //   );
    //   if (fieldIndex >= 2) nextSibling.focus();
    // }

    const { id } = e.target;
    setValue((prevState) => ({
      ...prevState,
      [id]: e.target.value,
    }));
  };

  const handleFocus = (e) => {
    const { value, name } = e.target;
    const fieldIndex = name.split("-")[1];
    if (e.key === "Backspace" && value.length === 0) {
      const nextSibling = document.querySelector(
        `input[name=field-${parseInt(fieldIndex, 12) - 1}]`
      );

      if (fieldIndex >= 2) nextSibling.focus();
    } else if (value.length > 3) {
      const nextSibling = document.querySelector(
        `input[name=field-${parseInt(fieldIndex, 12) + 1}]`
      );

      if (fieldIndex <= 2) nextSibling.focus();
    }
  };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {inputNo.map((_, index) => {
        return (
          <div>
            <Input
              inputMode="numeric"
              type={props.type ? "text" : "password"}
              maxLength="4"
              data-testid={`field${index + 1}`}
              autoFocus={index === 0}
              id={`field${index + 1}`}
              key={`field${index + 1}`}
              onChange={handleChange}
              name={`field-${index + 1}`}
              onKeyUp={handleFocus}
            />

            {index === inputNo.length - 1 ? null : (
              <span style={{ marginTop: "6px" }}>
                <VscDash />
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};
const Input = styled.input`
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  width: 80px;
  text-align: center;
  margin-right: 10px;
  font-size: 26px;
  &::-ms-reveal {
    display: none;
  }
`;
export default AadhaarInput;
