import React, { useEffect } from "react";
import styled from "styled-components";
const Unauthorized = () => {
  const callback = sessionStorage.getItem("callback")
  useEffect(()=>{
    let timeout
    if(callback){
    timeout= setTimeout(()=>{
        window.location.href=callback
      },3000)
    }
    return () => {
      clearTimeout(timeout);
    };
  },[])
  return <Container>
  <Card>Unauthorized user, {callback ? "redirecting to parent application" : "Please return to parent application"}</Card></Container>;
};

export default Unauthorized;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  height:100vh;
`;
const Card = styled.div`
display:flex;
align-items:center;
justify-content:center;
border-radius: 23px;
height: 250px;
width: 300px;
text-align: center;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;
