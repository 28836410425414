import styled from "styled-components";

export const Show = styled.div`
height: 100vh;
background: #ffffff;
display: flex;
flex-direction:column;
align-items:center;
justify-content: center;
width: 100%;
`;