import { createContext, useState } from "react";
export const Details = createContext();

const Context = ({ children }) => {
  const [ABHAID, setABHAID] = useState("");
  const [ABHANumber, setABHANumber] = useState("");
  const [parentDetails, setParentDetails] = useState({
    name: "",
    appId: 1,
    azureId: "",
    // partnerId: "mh", //removing hardcoded "mh"
    partnerId: "",
    url: "",
  });
  const [userInfo, setUserInfo] = useState({
    name: "",
    // relation: "",
    dateOfBirth: "",
    gender: "",
    email: "",
    phone: "",
    abhaId: "",
    abhaNo: "",
  });
  const [adhaarInfo, setAdhaarInfo] = useState({
    name: "",
    gender: "",
    mobile: "",
    email: "",
    yearOfBirth: "",
    dayOfBirth: "",
    monthOfBirth: "",
    address: "",
    districtName: "",
    stateName: "",
    healthId: "",
    healthIdNumber: "",
    pincode:"",
    kycPhoto: "",
    token: "",
  });
  return (
    <Details.Provider
      value={{
        userInfo,
        setUserInfo,
        setABHAID,
        ABHAID,
        ABHANumber,
        setABHANumber,
        adhaarInfo,
        setAdhaarInfo,
        parentDetails,
        setParentDetails,
      }}
    >
      {children}
    </Details.Provider>
  );
};
export default Context;
