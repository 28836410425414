import styled from "styled-components";

export const Consent = styled.div`
top: ${(props) => props.top || "440px"};
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
/* justify-content: center; */
// background: aqua;
// width: 222px;
justify-content: flex-start;
height: 20px;
align-items: center;
margin-top:24px;
`;