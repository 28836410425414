var CryptoJS = require("crypto-js");

// Encrypt
export const encrypt = (data) => {
  var cipherText = CryptoJS.AES.encrypt(
    data,
    process.env.REACT_APP_AZURE_CLIENTID
  ).toString();
  return cipherText;
};
// Decrypt
export const decrypt = (data) => {
  var bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_AZURE_CLIENTID);
  var originalText = bytes.toString(CryptoJS.enc.Utf8);

  return originalText;
};

export const setSessionStorageItem = (key, value) =>
  sessionStorage.setItem(key, value);
export const getSessionStorageItem = (key) => sessionStorage.getItem(key);

export const capitalizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};