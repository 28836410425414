import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
const SingletonFactory = (function () {
    let instance;
    return {
      getInstance: function () {
        if (instance == null) {
          instance = new PublicClientApplication(msalConfig);
          const accounts = instance.getAllAccounts();
          if (accounts.length > 0) {
              instance.setActiveAccount(accounts[0]);
          }
          instance.addEventCallback((event) => {
              if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                  const payload = event.payload;
                  const account = payload.account;
                  instance.setActiveAccount(account);
              }
          })
        }
        return instance;
      },
    };
  })();

  export const Instance = () => {
    return SingletonFactory.getInstance();
  };