import React from "react";
import Header from "../../UiComponent/screenUI/Header";

function HeaderSection() {
  return (
    <>
      <Header title="Link your ABHA" location="/" />
    </>
  );
}

export default HeaderSection;
