import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  TopSection,
  HeaderSection,
  ProfileImgContainer,
  ProfileNameContainer,
  AgeContainer,
  AbhaCardContainer,
  BottomSection,
  HealthIdContainer,
  HealthNumberContainer,
  PrescriptionPreview,
  PrescriptionPreviewHeader,
  PrescriptionPreviewPlace,
  PreviewFooter,
  PreviewIcon,
  PreviewName,
  PreviewText,
  ButtonConainer,
  ItemPreview,
  MainContainer,
  DownloadButton,
  ABHAButton,
} from "../../UiComponent/styledComponents/ABHAQrCard";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { get } from "../../Services/apiService";
import apiEndpoint from "../../Services/api";
import LoaderCmp from "../../UiComponent/loader/Loader";
import { Details } from "../../context/Context";
import Header from "../../UiComponent/screenUI/Header";

export default function AbhaQrCardNew() {
  const [profileImg, setProdileImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { parentDetails } = useContext(Details);
  const [abhaCardImg, setAbhaCardImg] = useState("");
  const [abhaCardQR, setAbhaCardQr] = useState("");
  const [showCard, setShowCard] = useState(false);
  const [age, setAge] = useState(0);
  const [data, setData] = useState({
    full_name: "",
    photo: [],
    birthDate: "",
    gender: "",
    health_id: "",
    health_number: "",
  });

  useEffect(() => {
    getUserData();
  }, []);
  const getUserData = async () => {
    setLoading(true);
    get(`${apiEndpoint.getABHAUser}?id=${parentDetails.azureId}`)
      .then((res) => {
        setData({
          ...res.data,
          full_name: res.data.full_name,
          birthDate: res.data.birthDate,
          gender: res.data.gender,
          health_id: res.data.health_id,
          health_number: res.data.health_number,
          photo: res.data.photo,
        });
        if (res.data.photo.length) {
          profileData(res.data.photo);
          abhaCardQr(res.data.photo);
          abhaCard(res.data.photo);
        }
        if (res.data.birthDate) {
          console.log("date", calculateAge(res.data.birthDate));
          setAge(calculateAge(res.data.birthDate));
        }
        setLoading(false);
        setIsError(false);
      })
      .catch((err) => {
        setLoading(false);
        setIsError(true);
      });
  };

  const profileData = (photo) => {
    if (photo.length) {
      photo.filter((data) => {
        if (data.title === "Profile photo") {
          setProdileImg(data.data);
          return data.data;
        }
      });
    }
  };

  const abhaCardQr = (photo) => {
    if (photo.length) {
      photo.filter((data) => {
        if (data.title === "ABHA QRcode") {
          setAbhaCardQr(data.data);
          return data.data;
        }
      });
    }
  };

  const abhaCard = (photo) => {
    if (photo.length) {
      photo.filter((data) => {
        if (data.title === "ABHA Card") {
          setAbhaCardImg(data.data);
          return data.data;
        }
      });
    }
  };

  const calculateAge = (dob) => {
    const dobDate = new Date(dob);
    const currentDate = new Date();
    const yearsDiff = currentDate.getFullYear() - dobDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const dobMonth = dobDate.getMonth();
    if (
      currentMonth < dobMonth ||
      (currentMonth === dobMonth && currentDate.getDate() < dobDate.getDate())
    ) {
      return yearsDiff - 1;
    }
    return yearsDiff;
  };

  function downloadBase64Image() {
    // Convert base64 to binary
    const binaryData = atob(abhaCardImg); // Create an array buffer from the binary data
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    } // Create a Blob from the array buffer
    const blob = new Blob([arrayBuffer], { type: "image/png" }); // Change the type as needed   // Create a data URL for the Blob
    const url = URL.createObjectURL(blob); // Create a hidden anchor element
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a); // Set the anchor's href to the data URL
    a.href = url; // Set the download attribute and filename
    a.download = "AbhaCard"; // Programmatically trigger a click event on the anchor element
    a.click(); // Clean up by revoking the data URL
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  return (
    <>
      {loading ? <LoaderCmp data-testid="loader" /> : null}
      <Header title={"My Profile"} location="navigateToParent" />
      <MainContainer>
        <Container>
          <TopSection>
            <HeaderSection>
              <ProfileImgContainer>
                <img src={`data:image/jpeg;base64,${profileImg}`} />
              </ProfileImgContainer>
              <ProfileNameContainer>{data.full_name}</ProfileNameContainer>
              <AgeContainer>
                {data.gender}, {age + " " + "years"}
              </AgeContainer>
            </HeaderSection>
          </TopSection>
          <AbhaCardContainer>
            {abhaCardQR ? (
              <img src={`data:image/jpeg;base64,${abhaCardQR}`} />
            ) : (
              <img src={`data:image/jpeg;base64,${abhaCardImg}`} />
            )}
          </AbhaCardContainer>
          <BottomSection>
            <HealthIdContainer>{data.health_id}</HealthIdContainer>
            <HealthNumberContainer>{data.health_number}</HealthNumberContainer>
            <ButtonConainer>
              <ABHAButton
                onClick={() => {
                  setShowCard(true);
                }}
              >
                View
              </ABHAButton>
              <ABHAButton
                onClick={() => {
                  downloadBase64Image();
                }}
              >
                Download
              </ABHAButton>
            </ButtonConainer>
          </BottomSection>
        </Container>
      </MainContainer>
      {showCard && (
        <PrescriptionPreview>
          <PrescriptionPreviewPlace>
            <PrescriptionPreviewHeader>
              <PreviewText>ABHA CARD</PreviewText>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setShowCard(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </PrescriptionPreviewHeader>

            <ItemPreview>
              {" "}
              <img src={`data:image/jpeg;base64,${abhaCardImg}`} />
            </ItemPreview>
            <ButtonConainer>
              <DownloadButton
                onClick={() => {
                  downloadBase64Image();
                }}
              >
                Download ABHA Card
              </DownloadButton>
            </ButtonConainer>
          </PrescriptionPreviewPlace>
        </PrescriptionPreview>
      )}
    </>
  );
}
