import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button,
  ButtonWrapper,
} from "../../UiComponent/styledComponents/Button";
import { Otp } from "../../UiComponent/styledComponents/Otp";
import { Text } from "../../UiComponent/styledComponents/Text";
import { Span } from "../../UiComponent/styledComponents/Span";
import OTP from "../../UiComponent/otpcmp/OtpCmp";
import OtpTime from "../../UiComponent/otpcmp/OtpTimer";
import HeaderCmp from "../../UiComponent/screenUI/Header";
import MainSection from "../../UiComponent/screenUI/MainSectionCmp";
import { useContext } from "react";
import { Details } from "../../context/Context";
import { submitUserDetails } from "../userDetails/AbhaAdd";
import apiEndpoint from "../../Services/api";
import { useLocation, useNavigate } from "react-router-dom";
import LoaderCmp from "../../UiComponent/loader/Loader";
import { post } from "../../Services/apiService";
import { verifyABHA } from "../LinkABHA/LinkAPI";
import {
  getSessionStorageItem,
  decrypt,
  setSessionStorageItem,
  encrypt,
} from "../../util/Helper";
import { createABHANumber } from "../userDetails/UserDetails";

function MobileOtp(props) {
  const [registered, setRegistered] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    userInfo,
    ABHANumber,
    setAdhaarInfo,
    ABHAID,
    parentDetails,
    setUserInfo,
  } = useContext(Details);

  const [Message, setMessage] = useState("");
  const [OTPValue, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  useEffect(() => {
    if (parentDetails?.partnerId === "SVAAS") {
      setRegistered(true);
    }
  }, []);

  useEffect(() => {
    if (serverError) {
      onInternalServerError();
    }
  }, [serverError]);

  useEffect(() => {
    if (props?.serverError) {
      onInternalServerError();
    }
  }, [props]);

  const onInternalServerError = () => {
    setMessage("Please try after sometime...");
    setTimeout(() => {
      setServerError(false);
      setMessage("");
      navigate("/");
    }, 5000);
  };
  // OTP INPUT value handler
  const OTPValueHandler = (otp) => {
    setOTP(otp);
    setMessage("");
  };
  //setting isRegistered value

  // calls OTP generator to resend OTP based on the flow(link/create)

  const OTPResendHandler = async () => {
    setMessage("");
    setOTP("");
    props.flow === "link" || location?.state?.flow === "create"
      ? await verifyABHA({
          healthId: ABHAID,
          heathIdNumber: ABHANumber,
          token: "resend",
          authMode: "MOBILE_OTP",
          isRegistered: registered,
        })
          .then((res) => {
            setLoading(false);
            setSessionStorageItem("txnId", encrypt(res.txn_id));
          })
          .catch((err) => {
            setLoading(false);
            if (err?.response?.status === 500) {
              setServerError(true);
            } else {
              if (err?.response?.data?.message) {
                setMessage(err.response.data.message);
              } else if (err?.response?.data?.error?.message) {
                setMessage(err.response.data.error.message);
              }
            }
          })
      : submitUserDetails({
          email: userInfo.email,
          mobile: userInfo.phone,
          txnId: decrypt(getSessionStorageItem("txnId")),
          healthId: userInfo.abhaId,
        })
          .then((res) => {
            setMessage("");
            setOTP("");
          })
          .catch((err) => {});
  };
  // submits the OTP entered based on flow(link/create)
  const submitOTPLifter = async () => {
    setLoading(true);
    await (location.state.flow === "create"
      ? createMobileOtp()
      : props
          .submitOTP(OTPValue, setLoading)
          .then((res) => {})
          .catch((err) => {
            setLoading(false);
            if (err?.response?.data?.message)
              setMessage(err.response.data.message);
            else if (err?.message) {
              setMessage(err.message);
            } else {
              setMessage(err);
            }
          }));
  };
  // create OTP submit api call
  const createMobileOtp = () => {
    const { appId, partnerId, azureId } = parentDetails;
    const txn_id = decrypt(getSessionStorageItem("txnId"));
    // const data = {
    //   app_action: "create",
    //   txnId: txn_id,
    //   otp: OTPValue,
    //   consent_id: "1",
    //   app_id: appId,
    //   user_id: azureId,
    //   partner_id: partnerId,
    // };
    const data = {
      otp: OTPValue,
      txnId: txn_id,
    };
    post(apiEndpoint.verifyMobileOtp, data)
      .then((response) => {
        // navigator(response);
        if (response.status === 200) {
          const success = createABHANumber(txn_id, setUserInfo, setAdhaarInfo);
          if (success) navigate("/SuccessABHA");
          else {
            setMessage("Something went wrong!");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 500) {
          setServerError(true);
        } else {
          if (err?.response?.data?.details?.[0].message) {
            if (err.response.data.details[0].code === "HIS-1013") {
              setMessage("Invalid OTP");
            } else if (err.response.data.details[0].code === "HIS-1041") {
              setMessage("Reached maximum attempts, try again after sometime");
            } else {
              setMessage(err.response.data.details[0].message);
            }
            return;
          }
          setMessage(err.response.data.message);
        }
      });
  };
  const navigator = (response) => {
    if (response.status === 201) {
      setLoading(false);
      const {
        name,
        gender,
        mobile,
        email,
        yearOfBirth,
        healthId,
        healthIdNumber,
        kycPhoto,
        token,
      } = response.data;
      setAdhaarInfo({
        name: name,
        gender: gender,
        mobile: mobile,
        email: email,
        yearOfBirth: yearOfBirth,
        healthId: healthId,
        healthIdNumber: healthIdNumber,
        kycPhoto: kycPhoto,
        token: token,
      });
      navigate("/Success", { state: { flow: "created" } });
    } else if (response.status === 202) {
      setLoading(false);
      setMessage("User is Already registered");
    }
  };

  const path =
    props.flow === "link"
      ? "/link"
      : location?.state?.flow === "create"
      ? "/"
      : "/AbhaId";

  return (
    <>
      {loading ? <LoaderCmp /> : null}
      <HeaderCmp title={"Enter OTP"} location={path} />
      <MainSection scroll="auto">
        <Container>
          <Text top="173px" style={{ textAlign: "left" }}>
            Enter OTP recieved on mobile number to verify your identity
          </Text>
          <Span>This number will be linked with ABHA</Span>
        </Container>
        <Otp top="307px">
          <OTP handleChange={OTPValueHandler} value={OTPValue} />
        </Otp>
        {Message && (
          <Text color="red" top="387px" data-testid="message">
            {Message}
          </Text>
        )}
        <OtpTime OTPResendHandler={OTPResendHandler} />
        <ButtonWrapper>
          <Button
            top="650px"
            disabled={OTPValue.length !== 6}
            onClick={submitOTPLifter}
          >
            Submit
          </Button>
        </ButtonWrapper>
      </MainSection>
    </>
  );
}

const Container = styled.div`
  margin-top: 32px;
`;

export default MobileOtp;
