import { useContext, useEffect, useState } from "react";
import SampleBoard from "../../onBoardingScreen/SampleBoard";
import Header from "./Header";
import Main from "./Main";
import { Details } from "../../context/Context";
import { capitalize } from "../../util/Capitalize";
// import { useMsal } from "@azure/msal-react";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../util/Helper";
// import { updateUserDetailsIntial } from "../../util/apis";

function HomePage() {
  const { setParentDetails, setUserInfo } = useContext(Details);
  const [onToggleChange, setOnToggleChange] = useState(false);
  const handleChange = () => {
    setSessionStorageItem("onBoarding", false);
    setOnToggleChange(false);
  };

  // const msal = useMsal();

  useEffect(() => {
    if (getSessionStorageItem("onBoarding")) {
      setOnToggleChange(false);
    } else {
      setOnToggleChange(true);
    }
  }, []);

  // //used when parent app is integrated!!

  // useEffect(() => {
  //   const account = msal.accounts[0];
  //   console.log("MASL Acc", account);
  //   // const userName = capitalize(account.name);
  //   const userName = "AzureUserName";
  //   const newUser = account.idTokenClaims.newUser;
  //   // console.log(newUser);
  //   let partner = "SVAAS";
  //   // if (newUser) {
  //   //   console.log("newuser FLow");
  //   //   partner = "Non-SVAAS";
  //   // }
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const patientId = urlParams.get("id");
  //   if (patientId) setSessionStorageItem("patientId", patientId);
  //   let id = patientId ? patientId : getSessionStorageItem("patientId");
  //   setParentDetails((prevState) => {
  //     return {
  //       ...prevState,
  //       name: userName,
  //       azureId: id && id.length > 0 ? id : account.idTokenClaims.sub,
  //       partnerId: partner,
  //     };
  //   });
  //   // setUserInfo((prevState) => {
  //   //   return { ...prevState, name: userName };
  //   // });
  //   if (id && id.length > 0) updateUserDetailsIntial(id, setUserInfo);
  // }, []);

  return (
    <>
      <Header />
      <Main />
    </>
  );
}

export default HomePage;
