import styled from "styled-components";

export const ProfileBackGround = styled.div`
  background-color: #e9fff2;
  height: 45px;
  width: 45px;
  border-radius: 30px;
  left: 15px;
  top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 30px;
    color: #43bc73;
  }
`;
