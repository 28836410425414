import styled from "styled-components";

export const Input = styled.input`
width: 312px;
height: 40px;
top: 99px;
left: 16px;
padding: 5px;
font-size: 20px;
outline: none;
box-sizing: border-box;
background: #ffffff;
border: 1px solid #e8e8e8;
border-radius: 4px;
`;