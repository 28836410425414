import { Section } from "../styledComponents/Section";
import { Icon } from "../styledComponents/Icon";
import { Title } from "../styledComponents/Title";
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();
  const navigateToParent = () => {
    window.location.href =
      sessionStorage.getItem("callback") ||
      process.env.REACT_APP_PARENT_REDIRECT_URL;
    sessionStorage.removeItem("userInfo");
    sessionStorage.removeItem("ABHANumber");
    sessionStorage.removeItem("ABHAId");
  };
  const handleBack = () => {
    if (props?.location) {
      props.location === "navigateToParent"
        ? navigateToParent()
        : navigate(props.location);
    }
  };
  return (
    <>
      <Section>
        <Icon>
          <IoChevronBackOutline
            data-testid="back-button"
            onClick={handleBack}
          />
        </Icon>
        {props?.title && <Title>{props.title}</Title>}
        {props.children}
      </Section>
    </>
  );
};
export default Header;
