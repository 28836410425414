import styled from "styled-components";

export const Timer = styled.div`
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  top: ${(props) => props.top || "436px"};
`;