import { Card } from "../styledComponents/Card";

const MainSection = (props) => {
  return (
    <>
      <Card
        top={props.top}
        position={props.position}
        height={props.height}
        overflow={props.scroll}
      >
        {props.children}
      </Card>
    </>
  );
};
export default MainSection;
