const apiEndpoint = {
  Base_Url: process.env.REACT_APP_API_ENDPOINT,
  generateAadhaarOtp: "/api/generate-aadhaar-otp",
  regenerateAadhaarOtp: "/api/regenerate-aadhaar-otp",
  verifyAadhaarOtp: "/api/verify-aadhaar-otp",
  verifyMobileOtp: "/api/verify-mobile-otp",
  createHealthId: "/api/create-health-id",
  existHealthId: "/api/exist-health-id",
  userDataStorage: "/api/user-data-storage",
  linkABHA: "/api/link-abha",
  linkOtpConfirm: "/api/link-otp-confirm",
  getABHAUser: "/api/get-abha-user",
  scanABHAQr: "/api/scan-abha-qr",
  fetchSharedProfile: "/api/fetch-shared-profile?",
  fetchAuthModes: "/api/fetch-auth-modes",
  searchByHealthID: "/api/search-by-healthid",
  generateMobileOtp: "/api/generate-mobile-otp",
  getSuggestionsABHA: "/api/get-suggestions-abha",
  createABHAAddress: "/api/create-abha-address",
  fetchABHAProfile: "/api/get-abha-profile",
  userAuthentication : "/api/user-auth"
};

export default apiEndpoint;
