import styled from "styled-components";

export const OutLinedButton = styled.button`
border: 2px solid #494e9d;
color: #494e9d;
width: 302px;
height: 59px;
min-height:59px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 8px;
font-size: 15px;
`;