import SvaasLoader from "../../Assets/Loader.gif";

const LoaderCmp = () => {
  return (
    <div
      style={{
        zIndex: "99999",
        height: "100%",
        width: "100%",
        textAlign: "center",
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <img
        style={{ height: "auto", width: "130px" }}
        alt="loader"
        src={SvaasLoader}
      ></img>
    </div>
  );
};
export default LoaderCmp;
