import SuccessABHANumber from "./component/success/SuccessABHANumber";
import AbhaCardNew from "./component/abhaCard/AbhaCardNew";
import ProfileEdit from "./component/userDetails/ProfileEdit";
import AbhaQrCardNew from "./component/abhaCard/AbhaQrCardNew";
import MobileOtp from "./component/mobileOtp/MobileOtp";
import Success from "./component/success/Success";
import LinkOTP from "./component/LinkABHA/LinkOTP";
import AbhaCard from "./component/abhaCard/AbhaCard";
import ScanQr from "./component/LinkABHA/ScanQr/ScanABHA";
import UserDetails from "./component/userDetails/UserDetails";
import AadhaarOtp from "./component/AdhaarOtp";
import AbhaAdd from "./component/userDetails/AbhaAdd";
import AbhaQrCard from "./component/abhaCard/AbhaQrCard";
import Link from "./component/LinkABHA/Link";
import FetchSharedProfile from "./component/LinkABHA/SharedProfile/FetchSharedProfile";
import Aadhaar from "./component/Aadhaar";
import HomePage from "./component/HomePage/HomePage";
import { Routes, Route } from "react-router-dom";

const Router = () => {
   return  (<Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/link" element={<Link />} />
      <Route path="/linkotp" element={<LinkOTP />} />
      <Route path="/abhacard" element={<AbhaCardNew />} />
      <Route path="/MobileOtp" element={<MobileOtp />} />
      <Route path="/Success" element={<Success />} />
      <Route path="/SuccessABHA" element={<SuccessABHANumber />} />
      <Route path="/AbhaCard" element={<AbhaCardNew />} />
      <Route path="/AbhaQrCard" element={<AbhaQrCardNew />} />
      <Route path="/AadhaarOtp" element={<AadhaarOtp />} />
      <Route path="/Details" element={<UserDetails />} />
      <Route path="/AbhaId" element={<AbhaAdd />} />
      <Route path="/Aadhaar" element={<Aadhaar />} />
      <Route path="/scan" element={<ScanQr />} />
      <Route path="/fetchProfile" element={<FetchSharedProfile />} />
      <Route path="/profile" element={<ProfileEdit />} />
    </Routes>)
};

export default Router;
