import apiEndpoint from "../Services/api";
import { get } from "../Services/apiService";

export const updateUserDetailsIntial = async (id, setUserInfo) => {
  try {
    const userData = await get(`${apiEndpoint.getABHAUser}?id=${id}`);
    if (userData?.status === 200 && userData?.data) {
      const [firstName, middleName, lastName] =
        userData.data.full_name.split(" ");
      await setUserInfo((prevState) => {
        return {
          ...prevState,
          firstName: firstName,
          middleName: middleName,
          lastName: lastName,
          name: userData.data.full_name ?? "",
          dateOfBirth: userData.data.birthDate ?? "",
          gender: userData.data.gender ?? "",
          phone: userData.data.telecom?.value ?? "",
        };
      });
    }
  } catch (error) {}
};
