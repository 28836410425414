import apiEndpoint from "../../../Services/api";
import { get } from "../../../Services/apiService";

// Calls fetch Shared API with Token as param
// const fetchSharedProfileUrl = "http://localhost:3001/api/fetch-shared-profile?tokenNumber=1234&userId=azureid"

export const fetchSharedProfileData = async (token, id, partnerId) => {
    // setLoading(true);
    console.log(`${apiEndpoint.fetchSharedProfile}${token}${partnerId}`);
    let fetchApiResponse = await get(`${apiEndpoint.fetchSharedProfile}tokenNumber=${token}&userId=${id}&partnerId=${partnerId}`)
        .then((res) => {
            // setData(res.data);
            // setLoading(false);
            // setShowCard(true);
            console.log(res.data);
            console.log(res.status)
            return res
        })
        .catch((err) => {
            // setLoading(false);
            // setIsError(true);
            console.log("Error")
            throw err;
        });
    return fetchApiResponse
};