import { CardBox, TextContainer } from "../../UiComponent/styledComponents/CardBox";
import {
  Profile,
  ImageContainer,
} from "../../UiComponent/styledComponents/Profile";
import { Text } from "../../UiComponent/styledComponents/Text";
import { AbhaDetails } from "../../UiComponent/styledComponents/AbhaDetails";
import { AbhaIdDetails } from "../../UiComponent/styledComponents/AbhaIdDetails";
import defaultProfileImg from "../../Assets/profile.jpg";


export const UserDetailsCard = ({ ProfileImg, name, gender, age }) => {
  return (
    <>
      <CardBox>
        <ImageContainer>
          <Profile src={ProfileImg || defaultProfileImg} />
        </ImageContainer>
        <TextContainer>
          <Text weight="700" line="24px">
            {name}
          </Text>
          <Text className="noMargin" weight="300" size="12px">
            {gender}, {`${age} years`}
          </Text>
        </TextContainer>
      </CardBox>
    </>
  );
};
export const DetailsCard = ({ health_id, health_number }) => {
  return (
    <>
      <AbhaDetails>
        <p style={{ fontSize: "14px", fontWeight: "700", color: "#494E9D" }}>
          ABHA Number
        </p>
        <p
          style={{
            fontSize: "16px",
            fontWeight: "700",
            color: "#5A5A5A",
            textAlign: "left",
          }}
        >
          {health_number}
        </p>
      </AbhaDetails>
      <AbhaIdDetails>
        <p style={{ fontSize: "14px", fontWeight: "700", color: "#494E9D" }}>
          ABHA address
        </p>
        <p
          style={{
            fontSize: "16px",
            fontWeight: "700",
            color: "#5A5A5A",
            textAlign: "left",
            // minWidth: "155.23px",
          }}
        >
          {health_id}
        </p>
      </AbhaIdDetails>
    </>
  );
};
