import { useContext, useState, useEffect } from "react";
import Header from "../../../UiComponent/screenUI/Header";
import QrReader from "react-qr-reader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Text } from "../../../UiComponent/styledComponents/Text";
import { QRBackGround } from "../../../UiComponent/styledComponents/QRBackGround";
import { QrSection } from "../../../UiComponent/styledComponents/QrSection";
import { CamLogo } from "../../../UiComponent/styledComponents/CamLogo";
import { Details } from "../../../context/Context";
import camera from "../../../Assets/Group 21679.png";
import apiEndpoint from "../../../Services/api";
import { post } from "../../../Services/apiService";
import { getAuthMethodsABHA, verifyABHA } from "../LinkAPI";
import { encrypt, setSessionStorageItem } from "../../../util/Helper";
import LoaderCmp from "../../../UiComponent/loader/Loader";
import OtpSelectionPopup from "../otpSelectionPopup/OtpSelectionPopup";
import errors from "react-qr-reader/lib/errors";

function ScanABHA() {
  const navigate = useNavigate();
  const { setUserInfo, parentDetails } = useContext(Details);
  const [startScan, setStartScan] = useState(true);
  const [message, setMessage] = useState("");
  const [dob, setDob] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("DEMOGRAPHICS");
  const [scannedABHANumber, setScannedABHANumber] = useState(null);
  const [registered, setRegistered] = useState(false);

  useEffect(() => {
    if (parentDetails?.partnerId === "SVAAS") {
      setRegistered(true);
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  // handles the scanned data and store the details

  const handleScan = (scanData) => {
    if (scanData && scanData !== "") {
      setStartScan(false);
      const data = JSON.parse(scanData);
      const {
        hidn,
        hid,
        phr,
        name,
        gender,
        dob,
        mobile,
        district_name,
        ["state name"]: stateName,
      } = data;
      if (hid || phr) {
        if (hidn && name && gender && dob && mobile) {
          const { appId, partnerId, azureId } = parentDetails;
          // if (phr) {

          //   const phrDob = moment(dob).format('DD/MM/YYYY');
          //   const dobPhr = moment(phrDob).format('YYYY/MM/DD');
          //   setDob(dobPhr);
          // }
          const phrDob = moment(dob, "DD-M-YYYY").format("YYYY-MM-DD");
          const data = {
            name: name,
            gender: gender,
            healthId: hid || phr,
            healthIdNumber: hidn,
            // mobile: "+91" + mobile,
            mobile: mobile,
            districtName: district_name || " ",
            stateName: stateName || " ",
            dateOfBirth: phrDob,
          };
          const apiData = {
            ...data,
            app_id: appId,
            partner_id: partnerId,
            user_id: azureId,
            app_action: "scan",
          };
          getAuthMethodsABHA({
            purpose: "KYC_AND_LINK",
            healthId: data.healthId,
          })
            .then((res) => {
              console.log("Auth Modes", res);
              if (res.includes("DEMOGRAPHICS")) {
                verifyIDHandler(
                  data.healthId ? data.healthId : null,
                  data.healthIdNumber ? data.healthIdNumber : null,
                  apiData
                );
              } else {
                setMessage(
                  "Something went wrong. Please try with mobile/aadhar OTP"
                );
              }
              // verifyIDHandler(
              //   data.healthId ? data.healthId : data.healthIdNumber,
              //   apiData
              // );
            })
            .catch((err) => {
              setMessage("Something went wrong. Please try again");
            });

          // post(apiEndpoint.scanABHAQr, apiData)
          //   .then((res) => {
          //     if (res.status === 200) {
          //       setUserInfo((prevState) => {
          //         return { ...prevState, name: name };
          //       });
          //       setStartScan(false);
          //       navigate("/Success", { state: { flow: "linked" } });
          //     } else if (res.status === 409) {
          //       setStartScan(false);
          //       setMessage("Your Health Id is already Linked");
          //     }
          //   })
          //   .catch((err) => {
          //     setMessage(err?.response?.data?.message || err.message);
          //   });
        } else if (!hidn && !hid) {
          setStartScan(false);
          setMessage("Scan the valid ABHA QR code");
        }
      } else {
        setStartScan(false);
        setMessage("Scan the valid ABHA QR code");
      }
    }
  };

  const verifyIDHandler = async (ABHAId, ABHANumber, apiData) => {
    setIsLoading(true);
    await verifyABHA({
      healthIdNumber: ABHANumber,
      healthId: ABHAId,
      isRegistered: registered,
      authMode: value,
    })
      .then((res) => {
        setStartScan(false);
        const txn_id = encrypt(res.txn_id);
        setSessionStorageItem("txnId", txn_id);
        submitDemographicDetails({ ...apiData, transactionId: res.txn_id });
        // navigate("/linkotp", { state: { flow: "link" } });
      })
      .catch((err) => {
        setIsLoading(false);
        setStartScan(false);
        if (err?.response?.data?.details?.[0]) {
          if (err.response.data.details[0].code === "HIS-1008") {
            setMessage(`Scan the valid ABHA QR Code`);
          } else {
            setMessage(err.response.data.details[0].message);
          }
        } else if (err?.response?.data?.error?.message) {
          setMessage(err.response.data.error.message);
        } else if (err?.response?.data?.message) {
          setMessage(err.response.data.message);
        }
      });
  };

  const submitDemographicDetails = async (apiData) => {
    let data = {
      ...apiData,
      type: "DEMOGRAPHIC",
      identifier: {
        type: "MOBILE",
        value: apiData.mobile,
      },
    };
    await post(apiEndpoint.linkOtpConfirm, data)
      .then((res) => {
        console.log({ res });
        if (res?.data?.error?.message) {
          setMessage(res?.data?.error?.message);
        } else {
          setUserInfo((prevState) => {
            return {
              ...prevState,
              name: res.data.auth.patient.name,
              abhaId: res.data.auth.patient.healthId,
              abhaNo: res.data.auth.patient.healthIdNumber,
              gender: res.data.auth.patient.gender,
            };
          });
          navigate("/Success", {
            state: { flow: "linked" },
          });
        }
      })
      .catch((err) => {
        if (err?.response?.data?.details?.[0].message) {
          setMessage("Invalid Details");
        } else if (err?.response?.status === 403) {
          setMessage(err?.response?.data);
        } else if (err?.response?.data?.error?.message) {
          setMessage(err.response.data.error.message);
        } else {
          setMessage("Something went wrong. Please try again");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // handles the scan errors

  const handleError = (error) => {
    setStartScan(false);
    console.log(error);
    setMessage("something went wrong!! please try after sometime...");
  };

  return (
    <>
      {isLoading ? <LoaderCmp /> : null}
      <Header title="Scan QR code" location="/link" />
      <QRBackGround></QRBackGround>
      {startScan ? (
        <QrSection>
          <QrReader
            key="environment"
            constraints={{ facingMode: "environment" }}
            delay={1000}
            onError={handleError}
            onScan={handleScan}
          />
        </QrSection>
      ) : null}
      {!message && startScan && (
        <Text top="490px" color="white" weight="700" left="calc(50% - 150px)">
          Scan your ABHA card
        </Text>
      )}
      {message && (
        <Text
          top="490px"
          color="red"
          left="calc(50% - 150px)"
          data-testid="message"
        >
          {message}
        </Text>
      )}

      {message && (
        <CamLogo
          src={camera}
          data-testid="rescan"
          onClick={() => {
            setStartScan(true);
            setMessage("");
          }}
        />
      )}
      <OtpSelectionPopup
        isOpen={isOpen}
        handleClose={handleClose}
        value={value}
        info={{
          healthId: scannedABHANumber,
          purpose: "KYC_AND_LINK",
        }}
        handleChange={handleChange}
        onSuccess={() => verifyIDHandler(scannedABHANumber)}
        setError={setMessage}
      />
    </>
  );
}

export default ScanABHA;
