import * as React from "react";
import styled from "styled-components";
import { useContext, useState, useEffect } from "react";
import {
  Button,
  ButtonWrapper,
} from "../../UiComponent/styledComponents/Button";

import { Links } from "../../UiComponent/styledComponents/Links";
import { LinkText } from "../../UiComponent/styledComponents/LinkText";
import { ABHAInputCard } from "../../UiComponent/styledComponents/ABHAInputCard";
import { ProfileBackGround } from "../../UiComponent/styledComponents/ProfileBackGround";
import { Input } from "../../UiComponent/styledComponents/Input";
import { Text } from "../../UiComponent/styledComponents/Text";
import { HR } from "../../UiComponent/styledComponents/HR";
import { useNavigate } from "react-router-dom";
import HeaderSection from "./HeaderSection";
import Main from "./Main";
import MainSection from "../../UiComponent/screenUI/MainSectionCmp";
import { getAuthMethodsABHA, searchByHealthId, verifyABHA } from "./LinkAPI";
import { Details } from "../../context/Context";
import LoaderCmp from "../../UiComponent/loader/Loader";
import { setSessionStorageItem, encrypt } from "../../util/Helper";
import OTPMode from "./OTPMode"; //New dailog
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import { Container } from "../../UiComponent/styledComponents/Containers";
import { ModalCloseIcon } from "../../UiComponent/styledComponents/ModalCloseIcon";
import { CloseIcon } from "../../UiComponent/styledComponents/CloseIcon";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import OtpSelectionPopup from "./otpSelectionPopup/OtpSelectionPopup";
import { FlexContainer } from "../../UiComponent/styledComponents/FlexContainer";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Link = () => {
  const [initalState, setInitialState] = useState(true);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // Dialog
  const [registered, setRegistered] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  // const handleModal = (event) => {
  //   // handleCheckBox(event);
  //   handleClose();
  // };

  const [value, setValue] = React.useState("MOBILE_OTP");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const { ABHANumber, setABHANumber, setABHAID, ABHAID, parentDetails } =
    useContext(Details);
  const [Message, setMessage] = useState("");

  useEffect(() => {
    setSessionStorageItem("ABHAId", ABHAID);
    setSessionStorageItem("ABHANumber", ABHANumber);
    if (parentDetails?.partnerId === "SVAAS") {
      setRegistered(true);
    }
  }, [ABHAID, ABHANumber]);

  // ABHA number Input change Handler function

  const ABHANoChangeHandler = (event) => {
    var abha = event.target.value;
    if (Message) {
      setMessage("");
    }
    if (isNaN(abha)) {
      setMessage("Enter valid ABHA number");
    }
    setABHANumber(abha);
  };

  // ABHA ID Input change Handler function

  const ABHAIDChangeHandler = (event) => {
    if (
      /^[a-zA-Z]+[a-zA-Z0-9]*[.]{0,1}[a-zA-Z0-9]*$/.test(event.target.value)
    ) {
      if (Message) {
        setMessage("");
      }
    } else {
      if (event.target.value.length === 0) {
        setMessage("");
      } else {
        setMessage("Enter a valid ABHA address");
      }
    }
    setABHAID(event.target.value);
  };

  // Verifies ABHA Number and sends OTP to user mobile

  const getOTPHandler = async () => {
    setIsOpen(false);
    setLoading(true);
    if (registered) {
      getAuthMethodsABHA({
        healthId: initalState ? ABHANumber : ABHAID,
        purpose: "KYC_AND_LINK",
      })
        .then((res) => {
          if (res.length === 0) {
            setMessage("Something went wrong! Please try again");
            setLoading(false);
          } else {
            verifyABHAHandler();
          }
        })
        .catch((err) => {
          setMessage("Invalid Details. Please try again!");
          setLoading(false);
        });
    } else {
      searchByHealthId({
        healthIdNumber: initalState ? ABHANumber : ABHAID,
      }).then((res) => {
        if (res.status === "Active" || res.status === "ACTIVE") {
          verifyABHAHandler(res.healthId, res.healthIdNumber);
        } else {
          setMessage("Invalid Details.Please try again");
        }
      });
    }
  };

  const verifyABHAHandler = async (
    healthId = initalState ? ABHANumber : ABHAID,
    healthIdNumber = ABHANumber
  ) => {
    await verifyABHA({
      healthId: healthId !== "" ? healthId : null,
      healthIdNumber: healthIdNumber !== "" ? healthIdNumber : null,
      isRegistered: registered,
      authMode: value,
    })
      .then((res) => {
        setLoading(false);
        const txn_id = encrypt(res.txn_id);
        setSessionStorageItem("txnId", txn_id);
        initalState
          ? setABHAID(res.healthId)
          : setABHANumber(res.healthIdNumber);
        navigate("/linkotp", { state: { flow: "link" } });
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.details?.[0]) {
          if (err.response.data.details[0].code === "HIS-1008") {
            setMessage(`Invalid ABHA ${initalState ? "number" : "id"}`);
          } else {
            setMessage(err.response.data.details[0].message);
          }
        } else if (err?.response?.data?.error?.message) {
          setMessage(err.response.data.error.message);
        } else if (err?.response?.data?.message) {
          setMessage(err.response.data.message);
        }
      });
  };

  return (
    <>
      {loading ? <LoaderCmp /> : null}
      <HeaderSection />
      <MainSection
        height="calc(100vh - 78px)"
        position="relative"
        scroll="auto"
      >
        <Main id={initalState ? "number" : "address"} />
        <ABHAInputCard>
          <Container>
            <ProfileBackGround>
              <span>
                {parentDetails?.name ? parentDetails.name[0].toUpperCase() : ""}
              </span>
            </ProfileBackGround>
            <FlexContainer className="container">
              <Text
                className="noMargin"
                width="fit-content"
                color="313131"
                right="70px"
                weight="700"
                size="14px"
              >
                {`ABHA ${initalState ? "number" : "address"}`}
              </Text>
              <Text
                className="noMargin"
                width="fit-content"
                top="23px"
                size="14px"
                left="35px"
                weight="200"
              >
                {`Link your account with ABHA ${
                  initalState ? "number" : "address"
                }`}
              </Text>
            </FlexContainer>
          </Container>
          <HR />
          <FlexContainer>
            <Input
              autoFocus={true}
              inputMode={initalState ? "numeric" : "text"}
              maxLength={initalState ? 14 : 32}
              placeholder={
                initalState ? "Enter your 14 digit ABHA number" : "username@sbx"
              }
              onChange={initalState ? ABHANoChangeHandler : ABHAIDChangeHandler}
              style={Message ? { borderColor: "red" } : null}
              value={initalState ? ABHANumber : ABHAID}
            />
          </FlexContainer>
        </ABHAInputCard>
        {Message && <Text color="red">{Message}</Text>}
        <div
          style={{
            // position: "absolute",
            width: "343px",
            height: "16px",
            top: "460px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LinkText
            onClick={() => {
              setMessage("");
              setInitialState((prevState) => {
                return !prevState;
              });
              setABHAID("")
              setABHANumber("")
            }}
          >
            {`Use ABHA ${initalState ? "Address" : "Number"}`}
          </LinkText>
        </div>
        <div
          style={{
            // position: "absolute",
            width: "343px",
            height: "16px",
            top: "505px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LinkText
            onClick={() => {
              navigate("/scan");
            }}
          >
            Scan ABHA QR Code
          </LinkText>
        </div>
        <div
          style={{
            // position: "absolute",
            width: "343px",
            height: "16px",
            top: "547px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LinkText
            onClick={() => {
              navigate("/fetchProfile");
            }}
          >
            Fetch Your Shared Profile
          </LinkText>
        </div>
        <ButtonWrapper>
          <Button
            left="calc(50% - 375px/2)"
            top="calc(50% - 59px/2 + 289px)"
            onClick={handleOpen}
            disabled={
              initalState
                ? ABHANumber?.length < 14
                : ABHAID?.length < 4 || Message
            }
          >
            Get OTP
          </Button>
        </ButtonWrapper>
        <OtpSelectionPopup
          isOpen={isOpen}
          handleClose={handleClose}
          value={value}
          handleChange={handleChange}
          onSuccess={getOTPHandler}
          setError={setMessage}
          info={{
            healthId: initalState ? ABHANumber : ABHAID,
            purpose: "LINK",
          }}
        />
      </MainSection>
    </>
  );
};

export default Link;
