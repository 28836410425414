import axios from "axios";
import apiEndpoint from "./api";
import { getSessionStorageItem } from "../util/Helper";
import { Instance } from "../authentication/instance";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../authentication/authConfig";
export const post = async (endPoint, data) => {
  const response = await axios(`${apiEndpoint.Base_Url}${endPoint}`, {
    method: "POST",
    params: {
      code: process.env.REACT_APP_API_ACCESS_INFO_CODE
    },
    data: data,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
  return response;
};

axios.interceptors.request.use(
  async (config) => {
    let token = getSessionStorageItem("token");
    if (token) {
      config.headers.common["authType"] = "jwt";
      config.headers.common["Authorization"] = `Bearer ${token}`;
      return config;
    } else {
      config.headers.common["Authorization"] = `Bearer ${await getToken()}`;
      return config;
    }
  },
  (error) => Promise.reject(error)
);
const getToken = async () => {
  const msalInstance = Instance();
  const accounts = msalInstance.getAllAccounts();
  try {
    const token = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });
    return token.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return msalInstance
        .acquireTokenRedirect(loginRequest)
        .then((response) => response.accessToken)
        .catch((err) => null);
    }
  }
};
export const get = async (endPoint) => {
  const response = await axios(`${apiEndpoint.Base_Url}${endPoint}`, {
    method: "GET",
    params: {
      code: process.env.REACT_APP_API_ACCESS_INFO_CODE
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
  return response;
};
