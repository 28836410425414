import styled from "styled-components";

export const Profile = styled.img`
width: 74px;
height: 74px;
border-radius: 8px;
position: absolute;
top: -60px;
bottom: 120px;
border-radius: 50%;
`;

export const ImageContainer =styled.div`
display:inline-flex;
justify-content:center;
width:100%;
position:relative;
`