import { Button, ButtonWrapper } from "../UiComponent/styledComponents/Button";
import { Text } from "../UiComponent/styledComponents/Text";
import { Logo } from "../UiComponent/styledComponents/Logo";
import { Consent } from "../UiComponent/styledComponents/Consent";
import { Alert } from "../UiComponent/styledComponents/Alert";
import { ModalCloseIcon } from "../UiComponent/styledComponents/ModalCloseIcon";
import { CloseIcon } from "../UiComponent/styledComponents/CloseIcon";
import logo from "../Assets/AadhaarLogo.png";
import Header from "../UiComponent/screenUI/Header";
import MainSection from "../UiComponent/screenUI/MainSectionCmp";
import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import LoaderCmp from "../UiComponent/loader/Loader";
import AadhaarInput from "../UiComponent/aadharInput/AdhaarInput";
import apiEndpoint from "../Services/api";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React from "react";
import Slide from "@mui/material/Slide";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { post } from "../Services/apiService";
import { encrypt, setSessionStorageItem } from "../util/Helper";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { contextType } from "react-modal";

export const otpGenerator = async (data) => {
  const response = await post(apiEndpoint.generateAadhaarOtp, data)
    .then((res) => {
      return res.data.txnId;
    })
    .catch((err) => {
      if (err?.response?.data?.details?.[0]?.message) {
        throw err.response.data.details[0].message;
      } else if (err?.response?.status === 403) {
        throw err.response.data.error_message;
      } else if (err?.response?.status === 500) {
        if (err?.response?.data?.message) {
          throw err.response.data.message;
        } else {
          throw err.response.statusText;
        }
      } else {
        throw err.response.statusText;
      }
    });
  return response;
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Aadhaar = () => {
  const [isdisable, setIsDisable] = useState(true);
  const [checked, setChecked] = useState(false);
  const [checkDisable, setCheckDisable] = useState(false);
  const [message, setMessage] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [aadhaarNumber, setAadhaarNumber] = useState("");
  const [consent, setConsent] = useState(false);
  const [inputType, setInputType] = useState(false);
  const [consentOptions, setConsentOptions] = useState({
    consent1: false,
    // consent2: false,
    consent3: false,
    consent4: false,
    consent5: false,
    consent6: true,
    consent7: false,
  });
  const navigate = useNavigate();
  const handleCheckBox = (event) => {
    const updatedConsentOptions = { ...consentOptions };
    let flag = false;
    if (event.target.checked) flag = true;
    for (const key in updatedConsentOptions) {
      if (key !== "consent6" || (key === "consent6" && flag))
        updatedConsentOptions[key] = flag;
    }
    setConsentOptions(updatedConsentOptions);
    setChecked(!checked);
    setConsent(event.target.checked);
    setIsDisable(!isdisable);
  };

  const handleChange = (value) => {
    const { field1, field2, field3 } = value;
    const userAdhaarNumber = field1 + field2 + field3;
    var validator = require("aadhaar-validator");
    if (userAdhaarNumber.length === 12) {
      if (!validator.isValidNumber(userAdhaarNumber)) {
        setMessage(true);
        setCheckDisable(true);
        setChecked(false);
      } else {
        setMessage(false);
        setCheckDisable(false);
        setAadhaarNumber(userAdhaarNumber);
      }
    } else if (userAdhaarNumber.length !== 12) {
      setIsDisable(true);
      setConsent(false);
      setCheckDisable(true);
      setChecked(false);
      setMessage(false);
    }
  };
  const handleConsent = (consentType, e) => {
    let updatedObject = {
      ...consentOptions,
      [consentType]: e.target.checked,
    };
    let flag = true;
    if (consentType === "consent7" && e.target.checked) {
      const updatedConsentOptions = { ...consentOptions };
      for (const key in updatedConsentOptions) {
        updatedConsentOptions[key] = true;
      }
      setConsentOptions(updatedConsentOptions);
      setChecked(true);
      setConsent(true);
    } else {
      Object.keys(consentOptions).forEach((key) => {
        if (
          (key === consentType && !e.target.checked) ||
          (key !== consentType && !consentOptions[key])
        ) {
          flag = false;
        }
      });
      setChecked(flag);
      setConsent(flag);
      setConsentOptions(updatedObject);
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleModal = (event) => {
    handleCheckBox(event);
    handleClose();
  };
  const handleAadhaar = async () => {
    setSessionStorageItem("aadhaar", encrypt(aadhaarNumber));
    setSessionStorageItem("consent", consent);
    setLoading(true);
    await otpGenerator({ aadhaar: aadhaarNumber, consentTaken: consent })
      .then((res) => {
        setSessionStorageItem("txnId", encrypt(res));
        setLoading(false);
        navigate("/AadhaarOtp");
      })
      .catch((err) => {
        setLoading(false);
        setAlertMessage("Something went wrong");
      });
  };
  const theme = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "20px 20px 0 0",
            minWidth: "375px",
            overflowX: "hidden",
            top: "35px",
          },
        },
      },
    },
  });
  return (
    <>
      {loading ? <LoaderCmp /> : null}
      <Header title="Enter AADHAAR" location="/" />
      <MainSection scroll="auto"> 
        <Logo src={logo} alt="Aadhaar Logo" />
        <Text top="294px">Enter AADHAAR number below :</Text>
        <Alert top="251px">{alertMessage}</Alert>
        <div style={{ display: "flex"}}>
          <AadhaarInput handleChange={handleChange} type={inputType} />
          <Checkbox
            checked={inputType}
            onClick={() => setInputType(!inputType)}
            icon={<AiFillEye />}
            checkedIcon={<AiFillEyeInvisible />}
            inputProps={{ "aria-label": "controlled" }}
            data-testid="checkbox"
          />
        </div>
        {message ? (
          <Alert top="399px">Enter a valid Aadhaar number</Alert>
        ) : null}
        <Consent top="530px">
          <Checkbox
            disabled={checkDisable}
            checked={checked}
            onClick={handleCheckBox}
            inputProps={{ "aria-label": "controlled" }}
            data-testid="checkbox"
          />
          <p style={{ fontSize: "12px", cursor: "pointer" }}>
            I agree to
            <span
              style={{ color: "#494E9D", fontWeight: "600" }}
              onClick={handleOpen}
            >
              {" "}
              Terms and Condition
            </span>
          </p>
        </Consent>
        <ButtonWrapper
        >
          <Button
            top="560px"
            onClick={handleAadhaar}
            disabled={checkDisable || !consent}
          >
            Submit
          </Button>
        </ButtonWrapper>
        <ThemeProvider theme={theme}>
          <Dialog
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            sx={{
              mt: "200px",
              top: "16%",
            }}
            PaperProps={{ style: { margin: "0px" } }}
            data-testid="modal-container"
          >
            <DialogActions
              sx={{
                height: "20px",
              }}
            >
              <CloseIcon>
                <ModalCloseIcon
                  data-testid="modal-close"
                  onClick={handleClose}
                />
              </CloseIcon>
            </DialogActions>
            <DialogContent>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={checkDisable}
                      checked={consentOptions.consent1}
                      onChange={(e) => handleConsent("consent1", e)}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ alignSelf: "start" }}
                    />
                  }
                  label="I am voluntarily sharing my Aadhaar Number / Virtual ID issued by the Unique Identification Authority of India (“UIDAI”), and my demographic information for the purpose of creating an Ayushman Bharat Health Account number (“ABHA number”) and Ayushman Bharat Health Account address (“ABHA Address”). I authorize NHA to use my Aadhaar number / Virtual ID for performing Aadhaar based authentication with UIDAI as per the provisions of the Aadhaar (Targeted Delivery of Financial and other Subsidies, Benefits and Services) Act, 2016 for the aforesaid purpose. I understand that UIDAI will share my e-KYC details, or response of “Yes” with NHA upon successful authentication."
                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      disabled={checkDisable}
                      checked={consentOptions.consent2}
                      onChange={(e) => handleConsent("consent2", e)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="I intend to create Ayushman Bharat Health Account Number (“ABHA number”) and Ayushman Bharat Health Account address (“ABHA Address”) using document other than Aadhaar. (Click here to proceed further)"
                />{" "} */}
                <FormControlLabel
                  sx={{ display: "flex" }}
                  control={
                    <Checkbox
                      disabled={checkDisable}
                      checked={consentOptions.consent3}
                      onChange={(e) => handleConsent("consent3", e)}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ alignSelf: "start" }}
                    />
                  }
                  label="I consent to usage of my ABHA address and ABHA number for linking of my legacy (past) government health records and those which will be generated during this encounter."
                />{" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={checkDisable}
                      checked={consentOptions.consent4}
                      onChange={(e) => handleConsent("consent4", e)}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ alignSelf: "start" }}
                    />
                  }
                  label="I authorize the sharing of all my health records with healthcare provider(s) for the purpose of providing healthcare services to me during this encounter."
                />{" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={checkDisable}
                      checked={consentOptions.consent5}
                      onChange={(e) => handleConsent("consent5", e)}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ alignSelf: "start" }}
                    />
                  }
                  label="I consent to the anonymization and subsequent use of my government health records for public health purposes."
                />
                <FormControlLabel
                  sx={{ ml: 2 }}
                  control={
                    <Checkbox
                      disabled={checkDisable}
                      checked={consentOptions.consent6}
                      onChange={(e) => handleConsent("consent6", e)}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ alignSelf: "start" }}
                    />
                  }
                  label="SVAAS, confirm that I have duly informed and explained the
                  beneficiary of the contents of consent for aforementioned
                  purposes."
                />
                <FormControlLabel
                  sx={{ ml: 2 }}
                  control={
                    <Checkbox
                      disabled={checkDisable}
                      checked={consentOptions.consent7}
                      onChange={(e) => handleConsent("consent7", e)}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ alignSelf: "start" }}
                    />
                  }
                  label="I, have been explained about the consent as stated above and
                  hereby provide my consent for the aforementioned purposes."
                />
              </FormGroup>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </ThemeProvider>
      </MainSection>
    </>
  );
};
export default Aadhaar;
