import styled from "styled-components";

export const AbhaIdDetails = styled.div`
display: flex;
justify-content: space-between;
position: absolute;
top: 363px;
font-size: 12px;
width: 274px;
height: 24px;
align-items: center;
`;