import styled from "styled-components";

export const Containers = styled.div`
width: 100%;
height: 100vh;
background-color: ${(props) => props.backgroundColor};
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
position: relative;
`;

export const Container = styled.div`
display: flex;
gap: 32px;
align-items: center;
justify-content:center;
.container {
  top: 12px;
  p {
    min-width: 240px;
    text-align: left;
    display: block;
  }
}
`;