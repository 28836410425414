import styled from "styled-components";
import { IoIosClose } from "react-icons/io";

export const ModalCloseIcon = styled(IoIosClose)`
background-color: White;
border-radius: 20px;
margin-top: -34px;
height: 36.25px;
width: auto;
border: 2px solid gray;
`;