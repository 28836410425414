import styled from "styled-components";

export const CamLogo = styled.img`
display: flex;
justify-content: center;
position: absolute;
width: 62px;
height: 62px;
top: 575px;
left: calc(50% - 24px);
`;