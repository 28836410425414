import "./App.css";
import { useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import AuthSelectDialog from './component/LinkABHA/AuthSelectDialog';
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Login from "./authentication/login";
import { Details } from "./context/Context";
import { getSessionStorageItem, setSessionStorageItem } from "./util/Helper";
import { updateUserDetailsIntial } from "./util/apis";
import Router from "./Routes";
import { get } from "./Services/apiService";
import apiEndpoint from "./Services/api";
import Unauthorized from "./UiComponent/Unauthorized";

function App({ msalInstance }) {
  const { setParentDetails, parentDetails,setUserInfo, setABHAID, setABHANumber } =
    useContext(Details);
  const [authToken, setAuthToken] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(null);
  useEffect(() => {
    if (getSessionStorageItem("userInfo")) {
      setUserInfo(JSON.parse(getSessionStorageItem("userInfo")));
    }
    if (getSessionStorageItem("ABHANumber")) {
      setABHANumber(getSessionStorageItem("ABHANumber"));
    }
    if (getSessionStorageItem("ABHAId")) {
      setABHAID(getSessionStorageItem("ABHAId"));
    }
  }, []);
    //used when parent app is integrated!!
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const patientId = urlParams.get("id");
    const token = urlParams.get("token");
    const name = urlParams.get("name")
    const clientId = urlParams.get("clientId");
    const userName = name  || "AzureUserName";
    let partner = "SVAAS";

    if (patientId) {
      setSessionStorageItem("patientId", patientId);
    }
    if (token) {
      setSessionStorageItem("token", token);
      setAuthToken(token);
      partner = clientId;
      setParentDetails((prevState) => {
        return {
          ...prevState,
          name: userName,
          azureId: patientId,
          partnerId: partner,
        };
      });
    } else {
      const accounts = msalInstance.getAllAccounts();
      if (accounts && accounts.length) {
        const account = accounts[0];
        setParentDetails((prevState) => {
          return {
            ...prevState,
            name: userName,
            azureId:
              patientId?.length > 0 ? patientId : account.idTokenClaims.sub,
            partnerId: partner,
          };
        });
      }
    }
    if (patientId?.length > 0) updateUserDetailsIntial(patientId, setUserInfo);
  }, []);
  useEffect(() => {
    const userAuthentication = async () => {
      try {
        const res = await get(apiEndpoint.userAuthentication);
        if (res.status == 200) {
          setIsAuthorized(true);
          setParentDetails((prevState) => {
            return {
              ...prevState,
              partnerId: res.data?.clientId,
              url:res.data?.callback
            };
          });
          setSessionStorageItem("callback", res.data?.callback);
        } else {
          setIsAuthorized(false);
        }
      } catch (err) {
        setIsAuthorized(false);
      }
    };
    if (authToken) {
      userAuthentication();
    }
  }, [authToken]);

  return (
    <BrowserRouter>
      {authToken ? (
       isAuthorized ? <Router /> : <Unauthorized />
      ) : (
        <MsalProvider instance={msalInstance}>
          <AuthenticatedTemplate>
            <Router />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Routes>
              <Route path="/" element={<Login />} />
            </Routes>
          </UnauthenticatedTemplate>
        </MsalProvider>
      )}
    </BrowserRouter>
  );
}
export default App;
