import styled from "styled-components";

export const Section = styled.div`
  display: flex;
  font-size: 24px;
  position:relative;
  align-items: center;
  font-weight: 700;
  color: #494e9d;
  line-height: 30px;
  font-style: normal;
  overflow: hidden;
`;