import OtpInput from "react18-input-otp";

const OTP = ({ handleChange, value }) => {
  return (
    <>
      <OtpInput
        value={value}
        onChange={handleChange}
        numInputs={6}
        separator={<span style={{ width: "12px" }}></span>}
        isInputNum={true}
        shouldAutoFocus={true}
        inputStyle={{
          width: "34px",
          height: "34px",
          fontSize: "22px",
          color: "#000",
          fontWeight: "400",
          caretColor: "blue",
          border: "none",
          borderBottom: "1px solid #5A5A5A",
        }}
        focusStyle={{
          outline: "none",
        }}
      />
    </>
  );
};
export default OTP;
