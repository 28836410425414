import {
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Autocomplete,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import React, { useContext } from "react";
import {
  Button,
  ButtonWrapper,
} from "../../UiComponent/styledComponents/Button";
import { Text } from "../../UiComponent/styledComponents/Text";
import { Slogo } from "../../UiComponent/styledComponents/Slogo";
import { Alert } from "../../UiComponent/styledComponents/Alert";
import HeaderCmp from "../../UiComponent/screenUI/Header";
import MainSection from "../../UiComponent/screenUI/MainSectionCmp";
import CorrectLogo from "../../Assets/Correct.png";
import WrongLogo from "../../Assets/Wrong.png";
import { useNavigate } from "react-router-dom";
import { Details } from "../../context/Context";
import apiEndpoint from "../../Services/api";
import LoaderCmp from "../../UiComponent/loader/Loader";
import { post } from "../../Services/apiService";
import {
  getSessionStorageItem,
  setSessionStorageItem,
  decrypt,
} from "../../util/Helper";

export const submitUserDetails = async (data) => {
  const response = await post(apiEndpoint.userDataStorage, data)
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
  return response;
};
const AbhaAdd = () => {
  const navigate = useNavigate();
  const { userInfo, setUserInfo, adhaarInfo, setAdhaarInfo, parentDetails } =
    useContext(Details);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState([]);

  React.useEffect(() => {
    getSuggestionsABHA();
  }, []);

  const getSuggestionsABHA = async () => {
    await post(apiEndpoint.getSuggestionsABHA, {
      txnId: decrypt(getSessionStorageItem("txnId")),
    })
      .then((response) => {
        console.log(response.data);
        setSuggestions(response.data);
      })
      .catch(() => {
        setSuggestions([]);
      });
  };

  const handleChange = (e, val) => {
    console.log("handle change called", val);
    const { name, value } = e.target;
    // if (/^[a-zA-Z]+[a-zA-Z0-9]*[.]{0,1}[a-zA-Z0-9]*$/.test(val)) {
    if (
      /^(?=[a-zA-Z0-9._]{8,18}$)(?!(.*[._]){2})[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)?$/.test(
        val
      )
    ) {
      if (alertMessage) {
        setAlertMessage("");
      }
    } else {
      if (val.length === 0) {
        setAlertMessage("");
      } else {
        setAlertMessage("Choose a valid ABHA address");
      }
    }
    setUserInfo({
      ...userInfo,
      abhaId: val,
    });
  };

  const handleAddress = () => {
    setAlertMessage("");
    setLoading(true);
    setSessionStorageItem("userInfo", JSON.stringify(userInfo));
    const { abhaId } = userInfo;

    if (abhaId) {
      const data = { healthId: abhaId };
      post(apiEndpoint.existHealthId, data)
        .then((response) => validator(response.status))
        .catch((err) => {
          setLoading(false);
          validator(err.response.status);
        });
    } else {
      setAlertMessage("Please enter the valid Abha Id");
    }
  };
  const navigator = (status, message) => {
    if (status === 200) {
      navigate("/Success", { state: { flow: "created", profile: true } });
      // navigate("/MobileOtp", { state: { flow: "create" } });
    } else if (status === 202) {
      setAlertMessage(message);
    } else {
      setAlertMessage("Some thing went wrong try again");
    }
  };
  const validator = (status) => {
    if (status === 200) {
      setError(true);
      setSuccess(false);
      setLoading(false);
    } else if (status === 404) {
      setSuccess(true);
      setError(false);
      handleData();
    }
  };

  const handleData = async () => {
    // const [firstName, middleName, lastName] = userInfo.name.split(" ");
    setLoading(true);
    const txn_id = decrypt(getSessionStorageItem("txnId"));
    await post(apiEndpoint.createABHAAddress, {
      phrAddress: userInfo.abhaId,
      transactionId: txn_id,
      // user_id: parentDetails.azureId,
      // data: {
      //   name: adhaarInfo.name,
      //   dayOfBirth: adhaarInfo.dayOfBirth,
      //   monthOfBirth: adhaarInfo.monthOfBirth,
      //   yearOfBirth: adhaarInfo.yearOfBirth,
      //   gender: adhaarInfo.gender,
      //   mobile: userInfo.phone,
      //   healthIdNumber: adhaarInfo.healthIdNumber,
      //   districtName: adhaarInfo.districtName,
      //   stateName: adhaarInfo.stateName,
      // },
    })
      .then((response) => {
        setAdhaarInfo((prevState) => ({
          ...prevState,
          token: response.data.token,
        }));
        setSessionStorageItem("abha-token", response.data.token);
        navigator(
          response.status,
          response.data.message ? response.data.message : ""
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        navigator();
      });
  };

  return (
    <>
      {loading ? <LoaderCmp /> : null}
      <HeaderCmp title={"Choose ABHA address"} location="/Details" />
      <MainSection>
        <Text top="238px" style={{ textAlign: "left", paddingLeft: "16px", marginTop:"64px" }}>
          ABHA address will be used to identify yourself by healthcare providers
        </Text>
        <Alert top="165px">{alertMessage}</Alert>
        <FormControl
          variant="outlined"
          style={{width:"324px"}}
        >
          <label
            for="outlined-adornment-weight"
            style={{ fontSize: "12px", color: "#5A5A5A", marginBottom: "10px" }}
          >
            ABHA address is similar to your UPI ID
          </label>
          <Autocomplete
            options={suggestions}
            disableClearable
            forcePopupIcon={false}
            freeSolo
            fullWidth
            onInputChange={handleChange}
            groupBy={() => "Suggestions"}
            inputValue={userInfo.abhaId}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => option}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  name="abhaId"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">@sbx</InputAdornment>
                    ),
                    maxLength: 32,
                  }}
                />
              );
            }}
          />

          {success || error ? null : (
            <FormHelperText
              style={{
                fontSize: "11px",
                color: "#BCBCBC",
                marginTop: "9px",
                marginLeft: "-2px",
                lineHeight: "16px",
              }}
              id="outlined-weight-helper-text"
            >
              (8-18 alphanumeric in length and can contain one special
              character(_or.) in middle)
            </FormHelperText>
          )}
        </FormControl>
        {success ? (
          <Container>
            <Slogo src={CorrectLogo} />
            <Text size="12px" width="200px" color="#BCBCBC" line="16px">
              ABHA address is available
              <br />
              Creating HealthId
            </Text>
          </Container>
        ) : null}
        {error ? (
          <Container>
            <Slogo src={WrongLogo} />
            <Text size="12px" width="200px" color="#BCBCBC" line="16px">
              ABHA address is not available
            </Text>
          </Container>
        ) : null}
        <ButtonWrapper>
          <Button
            top="650px"
            onClick={handleAddress}
            disabled={userInfo?.abhaId.length < 4 || alertMessage}
          >
            Submit
          </Button>
        </ButtonWrapper>
      </MainSection>
    </>
  );
};

export default AbhaAdd;

const Container = styled.div`
display:flex;
align-items:center;
`