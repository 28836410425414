import styled from "styled-components";

export const Button = styled.button`
  border: none;
  border-radius: ${(props) => props.borderRadius || ""};
  top: ${(props) => props.top || "489px"};
  // top:calc(50% - 59px/2 + 376.5px)
  width: ${(props) => props.width || "375px"};
  max-width: 375px;
  left: ${(props) => props.left || ""};
  // height: 59px;
  height: ${(props) => props.height || "59px"};
  min-height: 59px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #494e9d;
  color: white;
  // box-shadow: -8px 0px 15px rgb(207 207 207 / 25%);
  // padding: 12px 16px;
  box-shadow: 8px 0px 15px;
  font-size: 15px;
  overflow:hidden;
  &:disabled {
    background-color: #ede7f6;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonWrapper = styled.div`
  flex: 1 1 0%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
