import React from "react";
import { ABHALogo } from "../../UiComponent/styledComponents/ABHALogo";
import { Text } from "../../UiComponent/styledComponents/Text";
import { Button } from "../../UiComponent/styledComponents/Button";
import { OutLinedButton } from "../../UiComponent/styledComponents/OutLinedButton";
import MainSection from "../../UiComponent/screenUI/MainSectionCmp";
import AbhaLogo from "../../Assets/Group 1.png";
import { useNavigate } from "react-router-dom";
import { Details } from "../../context/Context";
import { useContext } from "react";
import { capitalize } from "../../util/Capitalize";

function Main() {
  const navigate = useNavigate();

  const { userInfo } = useContext(Details);
  return (
    <>
      <MainSection

        height="calc( 100vh - 242px)"
        scroll="auto"
      >
        <ABHALogo src={AbhaLogo} alt="abha logo" />
        <Text className="noMargin" top="160px" size="24px" color="black">
          Welcome !
        </Text>
        {userInfo?.name && <Text className="noMargin" top="194px" size="20px" weight="500" color="#DE3534">
          {userInfo?.name && userInfo.name !== ""
            ? " " + userInfo.name + " "
            : " "}
        </Text>}
        <Text className="noMargin" top="237px">Be a part of India Digital Health Mission</Text>
        <Button
          width="302px"
          borderRadius="8px"
          onClick={() => {
            navigate("/Aadhaar");
          }}
        >
          Create ABHA
        </Button>
        <Text top="385px" size="12px">
          Already have ABHA?
        </Text>
        <OutLinedButton
          onClick={() => {
            navigate("/link");
          }}
        >
          Share your ABHA
        </OutLinedButton>
      </MainSection>
    </>
  );
}

export default Main;
