import styled from "styled-components";

export const LinkText = styled.button`
    color: #2638d8e6;
    background-color: #FFFFFF;
    height: 36px;
    width: 343px;
    border-radius: 4px;
    border: groove;
    list-style-type: none;
`;