import HeaderCmp from "../../UiComponent/screenUI/Header";
import FormControl from "@mui/material/FormControl";
import { MenuItem, TextField } from "@mui/material";
import {
  Button,
  ButtonWrapper,
} from "../../UiComponent/styledComponents/Button";
import { FormContent } from "../../UiComponent/styledComponents/FormContent";
import { Label } from "../../UiComponent/styledComponents/Label";
import { genderData } from "./FormData";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useContext, useState } from "react";
import { Details } from "../../context/Context";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  capitalizeString,
  decrypt,
  encrypt,
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../util/Helper";
import apiEndpoint from "../../Services/api";
import { post } from "../../Services/apiService";
import { Text } from "../../UiComponent/styledComponents/Text";
import LoaderCmp from "../../UiComponent/loader/Loader";

export const createABHANumber = async (txnId, setUserInfo, setAdhaarInfo) => {
  await post(apiEndpoint.createHealthId, {
    txnId,
  })
    .then(async (res) => {
      if (res.data.transactionId)
        setSessionStorageItem("txnId", encrypt(res.data.transactionId));
      let firstName, middleName, lastName;
      if (res.data?.name) {
        [firstName, middleName, lastName] = res.data.name.split(" ");
      }
      await setUserInfo((prevState) => {
        return {
          ...prevState,
          firstName: firstName ? firstName : "",
          middleName: middleName ? middleName : "",
          lastName: lastName ? lastName : "",
          name: res.data.name ? res.data.name : "",
          dateOfBirth:
            res.data.dayOfBirth +
            "-" +
            res.data.monthOfBirth +
            "-" +
            res.data.yearOfBirth,
          gender: res.data.gender,
          email: res.data.email ? res.data.email : "",
        };
      });
      await setAdhaarInfo((prevState) => ({
        ...prevState,
        name: res.data.name ? res.data.name : null,
        gender: res.data.gender ? res.data.gender : null,
        yearOfBirth: res.data.yearOfBirth ? res.data.yearOfBirth : null,
        monthOfBirth: res.data.monthOfBirth ? res.data.monthOfBirth : null,
        dayOfBirth: res.data.dayOfBirth ? res.data.dayOfBirth : null,
        healthIdNumber: res.data.healthIdNumber
          ? res.data.healthIdNumber
          : null,
        stateName: res.data.stateName ? res.data.stateName : null,
        districtName: res.data.districtName ? res.data.districtName : null,
        address: res.data.address ? res.data.address : null,
      }));
      return true;
    })
    .catch((error) => {
      return false;
    });
};

const UserDetails = () => {
  const { userInfo, setUserInfo, parentDetails, setAdhaarInfo } =
    useContext(Details);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    // fullName: Yup.string()
    //   .required("Full name is required")
    //   .min(5, "Name should of min 5 character"),
    // relation: Yup.string().required("Relation is required"),
    // dateOfBirth: Yup.string().nullable().required("Date of Birth is required"),
    // gender: Yup.string().required("Gender is required"),
    // email: Yup.string()
    //   .email("Please enter a valid email"),
    phone: Yup.string()
      .required("Phone is required")
      .min(10, "Phone no. must be 10 digit")
      .max(10, "Phone no. must be 10 digit")
      .matches(phoneRegExp, "Phone no. is not valid"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    let firstName, middleName, lastName;
    if (data?.fullName) {
      [firstName, middleName, lastName] = data.fullName.split(" ");
    }
    let phoneNumber = data.phone;
    console.log({phoneNumber});
    await setUserInfo((prevState) => {
      return {
        ...prevState,
        firstName: firstName ? firstName : "",
        middleName: middleName ? middleName : "",
        lastName: lastName ? lastName : "",
        name: data?.fullName ? data.fullName : "",
        dateOfBirth: data.dateOfBirth,
        gender: data.gender,
        email: data.email,
        phone: phoneNumber,
      };
    });
    // navigate("/AbhaId");
    const transactionId = decrypt(getSessionStorageItem("txnId"));
    await post(apiEndpoint.generateMobileOtp, {
      txnId: transactionId,
      mobile: data.phone,
    })
      .then(async (res) => {
        setError(false);
        setSessionStorageItem("txnId", encrypt(res.data.txnId));
        if (res.data.mobileLinked) {
          await createABHANumber(transactionId, setUserInfo, setAdhaarInfo)
            .then((res) => {
              setLoading(false);
              navigate("/SuccessABHA");
            })
            .catch((err) => {
              setLoading(false);
              setError(true);
            });
        } else {
          setLoading(false);
          navigate("/MobileOtp", { state: { flow: "create" } });
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <>
    {loading ? <LoaderCmp /> : null}
      <HeaderCmp title={"Enter Mobile Number"} location={"/"} />

      <FormContent>
        <FormControl>
          {/* <Label>Full Name*</Label>
          <TextField
            data-testid="name"
            id="relation"
            name="fullName"
            variant="outlined"
            sx={{ width: 290, marginBottom: "20px" }}
            InputProps={{
              sx: { height: 37, background: "#fff" },
            }}
            {...register("fullName")}
            defaultValue={userInfo.name ? userInfo.name : null}
            error={errors.fullName ? true : false}
            helperText={errors.fullName?.message}
            FormHelperTextProps={{
              sx: {
                fontSize: "12px",
                position: "absolute",
                top: "34px",
                left: "-14px",
              },
            }}
          /> */}
          {
            // <Label>Relationship*</Label>
            // <TextField
            //   select
            //   id="relation"
            //   data-testid="relationship"
            //   variant="outlined"
            //   name="relation"
            //    defaultValue={userInfo.relation}
            //   sx={{ width: 290, marginBottom: "20px" }}
            //   InputProps={{
            //     sx: { height: 37, background: "#fff" },
            //   }}
            //   {...register("relation")}
            //   error={errors.relation ? true : false}
            //   helperText={errors.relation?.message}
            //   FormHelperTextProps={{
            //     sx: {
            //       fontSize: "12px",
            //       position: "absolute",
            //       top: "34px",
            //       left: "-14px",
            //     },
            //   }}
            // >
            //   {relationData.map((item) => (
            //     <MenuItem key={item.value} value={item.value} data-testid={item.value}>
            //       {item.label}
            //     </MenuItem>
            //   ))}
            // </TextField>
          }
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Label>Date of Birth (dd/mm/yyyy) *</Label>
            <Controller
              name="dateOfBirth"
              control={control}
              defaultValue={userInfo.dateOfBirth}
              render={({
                field: { onChange, value },
                fieldState: { error, invalid },
              }) => (
                <MobileDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={value}
                  name="dateOfBirth"
                  showToolbar={false}
                  disableFuture
                  data-testid="dob-feild"
                  onChange={(value) => {
                    onChange(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="dateOfBirth"
                      data-testid="dob"
                      defaultValue={userInfo.dateOfBirth}
                      sx={{ width: 290, marginBottom: "20px" }}
                      InputProps={{
                        sx: { height: 37, background: "#FFFFFF" },
                      }}
                      {...register("dateOfBirth")}
                      error={invalid}
                      helperText={
                        errors.dateOfBirth && errors.dateOfBirth.message
                      }
                      FormHelperTextProps={{
                        sx: {
                          fontSize: "12px",
                          position: "absolute",
                          top: "34px",
                          left: "-14px",
                        },
                      }}
                    />
                  )}
                />
              )}
            />
          </LocalizationProvider> */}

          {/* <Label>Gender*</Label>
          <TextField
            select
            id="gender"
            data-testid="gender"
            variant="outlined"
            name="gender"
            defaultValue={capitalizeString(userInfo.gender)}
            sx={{ width: 290, marginBottom: "20px" }}
            InputProps={{
              sx: { height: 37, background: "#FFFFFF" },
            }}
            {...register("gender")}
            error={errors.gender ? true : false}
            helperText={errors.gender?.message}
            FormHelperTextProps={{
              sx: {
                fontSize: "12px",
                position: "absolute",
                top: "34px",
                left: "-14px",
              },
            }}
          >
            {genderData.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField> */}

          {/* <Label>Email Address</Label>
          <TextField
            id="email"
            data-testid="email"
            name="email"
            defaultValue={userInfo.email}
            variant="outlined"
            sx={{ width: 290, marginBottom: "20px" }}
            InputProps={{
              sx: { height: 37, background: "#FFFFFF" },
            }}
            {...register("email")}
            error={errors.email ? true : false}
            helperText={errors.email?.message}
            FormHelperTextProps={{
              sx: {
                fontSize: "12px",
                position: "absolute",
                top: "34px",
                left: "-14px",
              },
            }}
          /> */}
          <Label></Label>
          <TextField
            id="phone"
            data-testid="phone"
            name="phone"
            variant="outlined"
            placeholder="Enter 10 digit mobile number"
            sx={{ width: 290, margin: "42px 0 20px 0" }}
            InputProps={{
              sx: { height: 37, background: "#FFFFFF" },
            }}
            {...register("phone")}
            // defaultValue={
            //   userInfo.phone?.length > 10
            //     ? userInfo.phone.slice(3)
            //     : userInfo.phone
            // }
            error={errors.phone ? true : false}
            helperText={errors.phone?.message}
            FormHelperTextProps={{
              sx: {
                fontSize: "12px",
                position: "absolute",
                top: "34px",
                left: "-14px",
              },
            }}
          />
          <Label>
            Add Alternate or Aadhaar linked mobile number for ABHA related
            communications
          </Label>
          {/* <Label style={{ color: "#494E9D", fontWeight: 500 }}>
            This will help in receiving ABHA communications
          </Label> */}
        </FormControl>
        {error && (
          <Text top="65vh" size="12px" color="#BCBCBC" line="16px">
            Something went wrong
          </Text>
        )}
        <ButtonWrapper>
          <Button top="75vh" onClick={handleSubmit(onSubmit)}>
            Verify
          </Button>
        </ButtonWrapper>
      </FormContent>
    </>
  );
};
export default UserDetails;
