import styled from "styled-components";

export const CloseIcon = styled.div`
position: fixed;
margin-top: "-34px";
width: 100%;
borderradius: "20px 20px 0 0";
/* background-color: antiquewhite; */
left: 0px;
justify-content: center;
display: flex;
font-size: 20px;
color: #494e9d;
// ZIndex:9;
`;