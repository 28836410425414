import styled from "styled-components";

export const AbhaDetails = styled.div`
display: flex;
font-size: 12px;
width: 274px;
height: 24px;
justify-content: space-between;
position: absolute;
top: 418px;
align-items: center;
`;