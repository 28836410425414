import { useContext, useEffect, useState } from "react";
import MobileOtp from "../mobileOtp/MobileOtp";
import apiEndpoint from "../../Services/api";
import { useLocation, useNavigate } from "react-router-dom";
import { Details } from "../../context/Context";
import LoaderCmp from "../../UiComponent/loader/Loader";
import { post } from "../../Services/apiService";
import { decrypt, getSessionStorageItem } from "../../util/Helper";

function LinkOTP() {
  const flow = useLocation().state.flow;
  const { setUserInfo, parentDetails } = useContext(Details);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (serverError) {
      setTimeout(() => {
        navigate("/");
      }, 5000);
    }
  }, [serverError]);
  // verifies OTP entered

  const submitOTP = async (OTPValue, setMainLoading) => {
    setLoading(true);
    const { appId, partnerId, azureId } = parentDetails;
    const txn_id = decrypt(getSessionStorageItem("txnId"));
    const data = {
      transactionId: txn_id,
      otp: OTPValue,
      app_id: appId,
      partner_id: partnerId,
      user_id: azureId,
      app_action: "link",
      type: "OTP",
      healthId: sessionStorage.getItem("ABHAId"),
      healthIdNumber: sessionStorage.getItem("ABHANumber"),
    };
    const response = await post(apiEndpoint.linkOtpConfirm, data)
      .then((res) => {
        if (res?.data?.error?.message) {
          setLoading(false);
          setMainLoading && setMainLoading(false);
          throw res.data.error.message;
        } else {
          setUserInfo((prevState) => {
            return {
              ...prevState,
              name: res.data.auth.patient.name,
              abhaId: res.data.auth.patient.healthId,
              abhaNo: res.data.auth.patient.healthIdNumber,
              gender: res.data.auth.patient.gender,
            };
          });
          setLoading(false);
          setMainLoading && setMainLoading(false);
          navigate("/Success", {
            state: { flow: "linked" },
          });
          return;
        }
      })
      .catch((err) => {
        setLoading(false);
        setMainLoading && setMainLoading(false);
        if (err?.response?.data?.details?.[0].message) {
          throw "Invalid OTP";
        }
        if (err?.response?.status === 403) {
          throw err.response.data;
        } else if (err?.response?.status === 400) {
          throw err?.response?.data?.error?.message || "Invalid OTP";
        } else if (err?.response?.status === 504) {
          throw "Service unavailable, Please try after sometime";
        } else if (err?.response?.data?.error?.message) {
          throw err.response.data.error.message;
        } else {
          if (err?.response?.status === 500 || err?.response?.status === 504) {
            setServerError(true);
          } else {
            throw err;
          }
        }
      });
    return response;
  };

  return (
    <>
      {loading ? <LoaderCmp /> : null}
      <MobileOtp submitOTP={submitOTP} flow={flow} serverError={serverError} />
    </>
  );
}

export default LinkOTP;
