import HeaderCmp from "../../UiComponent/screenUI/Header";
import FormControl from "@mui/material/FormControl";
import { MenuItem, TextField } from "@mui/material";
import { Button } from "../../UiComponent/styledComponents/Button";
import { FormContent } from "../../UiComponent/styledComponents/FormContent";
import { Label } from "../../UiComponent/styledComponents/Label";
import { genderData, genderDataMaping } from "./FormData";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useContext, useState } from "react";
import { Details } from "../../context/Context";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  capitalizeString,
  getSessionStorageItem,
} from "../../util/Helper";
import apiEndpoint from "../../Services/api";
import {  post } from "../../Services/apiService";
import { Text } from "../../UiComponent/styledComponents/Text";
import { useEffect } from "react";
import LoaderCmp from "../../UiComponent/loader/Loader";

const ProfileEdit = () => {
  const { userInfo, setUserInfo, parentDetails, setAdhaarInfo, adhaarInfo } =
    useContext(Details);
  const [error, setError] = useState(false);
  const [load, setLoad] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    let token = adhaarInfo.token
      ? adhaarInfo.token
      : getSessionStorageItem("abha-token");
    await post(apiEndpoint.fetchABHAProfile, { token })
      .then(async (res) => {
        setLoad(false);
        setError(false);
        let prevAddress = adhaarInfo.address;
        await setAdhaarInfo((prevState) => ({
          ...prevState,
          name: res.data.name,
          gender: res.data.gender,
          dateOfBirth: res.data.dateOfBirth,
          address: res.data.address && res.data.address!=="" ? res.data.address : prevAddress,
          healthId: res.data.healthId,
          healthIdNumber: res.data.healthIdNumber,
          pincode: res.data.pincode ? res.data.pincode : "",
          kycPhoto: res.data.profilePhoto,
          stateName: res.data.stateName ? res.data.stateName : "",
        }));
        await setUserInfo((prevState) => ({
          ...prevState,
          dateOfBirth: res.data.dateOfBirth,
          abhaId: res.data.healthId,
          abhaNo: res.data.healthIdNumber,
        }));
      })
      .catch(() => {
        setLoad(false);
        setError(true);
      });
  };

  const validationSchema = Yup.object().shape({
    // fullName: Yup.string()
    //   .required("Full name is required")
    //   .min(5, "Name should of min 5 character"),
    // dateOfBirth: Yup.string().nullable().required("Date of Birth is required"),
    // gender: Yup.string().required("Gender is required"),
    // email: Yup.string().email("Please enter a valid email"),
    // phone: Yup.string()
    //   .required("Phone is required")
    //   .min(10, "Phone no. must be 10 digit")
    //   .max(10, "Phone no. must be 10 digit")
    //   .matches(phoneRegExp, "Phone no. is not valid"),
    stateName: Yup.string().required("State name is required"),
    address: Yup.string().required("Address is required"),
    pincode: Yup.string()
      .required("Pincode is required")
      .max(6, "Enter a valid pincode")
      .min(6, "Enter a valid pincode"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setLoad(true);
    let firstName, middleName, lastName;
    if (data?.fullName) {
      [firstName, middleName, lastName] = data.fullName.split(" ");
    }
    // await setUserInfo((prevState) => {
    //   return {
    //     ...prevState,
    //     firstName: firstName ? firstName : "",
    //     middleName: middleName ? middleName : "",
    //     lastName: lastName ? lastName : "",
    //     name: data?.fullName ? data.fullName : "",
    //     // relation: data.relation,
    //     dateOfBirth: data.dateOfBirth,
    //     gender: data.gender,
    //     email: data.email,
    //     phone: data.phone,
    //   };
    // });
    // navigate("/AbhaId");
    const { azureId } = parentDetails;
    const token = adhaarInfo.token ? adhaarInfo.token : getSessionStorageItem('abha-token');
    await post(apiEndpoint.userDataStorage, {
      user_id: azureId,
      token,
      phrAdress: adhaarInfo.healthId,
      data: {
        name: adhaarInfo.name,
        gender: adhaarInfo.gender,
        mobile: userInfo.phone,
        healthIdNumber: adhaarInfo.healthIdNumber,
        pincode: data.pincode,
        address: data.address,
        stateName: data.stateName,
        profilePhoto: adhaarInfo.kycPhoto,
        date_of_birth: adhaarInfo.dateOfBirth,
        monthOfBirth: adhaarInfo.monthOfBirth,
        yearOfBirth: adhaarInfo.yearOfBirth,
        dayOfBirth: adhaarInfo.dayOfBirth,
      },
    })
      .then((res) => {
        setError(false);
        setLoad(false);
        if (res.status === 201) {
          setError(false);
          navigate("/AbhaQrCard");
        } else {
          setError(true);
          setErrorMessage(res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        setError(true);
      });
  };

  return (
    <>
      {load ? <LoaderCmp /> : null}
      <HeaderCmp title={"Verify ABHA Profile"} />
      {!load && (
        <FormContent>
          <FormControl className="scroll" style={{height:"calc(100vh - 78px)",overflow:"auto"}}>
            <Label>Full Name</Label>
            <TextField
              data-testid="name"
              disabled
              id="relation"
              name="fullName"
              variant="outlined"
              sx={{ width: 290, marginBottom: "20px" }}
              InputProps={{
                sx: { height: 37, background: "#fff" },
              }}
              {...register("fullName")}
              defaultValue={adhaarInfo.name ? adhaarInfo.name : null}
              FormHelperTextProps={{
                sx: {
                  fontSize: "12px",
                  position: "absolute",
                  top: "34px",
                  left: "-14px",
                },
              }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Label>Date of Birth (dd/mm/yyyy)</Label>
              <Controller
                name="dateOfBirth"
                control={control}
                defaultValue={adhaarInfo.dateOfBirth}
                render={({
                  field: { onChange, value },
                  fieldState: { error, invalid },
                }) => (
                  <MobileDatePicker
                    inputFormat="DD/MM/YYYY"
                    disabled
                    value={value}
                    name="dateOfBirth"
                    showToolbar={false}
                    disableFuture
                    data-testid="dob-feild"
                    onChange={(value) => {
                      onChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="dateOfBirth"
                        data-testid="dob"
                        sx={{ width: 290, marginBottom: "20px" }}
                        InputProps={{
                          sx: { height: 37, background: "#FFFFFF" },
                        }}
                        {...register("dateOfBirth")}
                        error={invalid}
                        helperText={
                          errors.dateOfBirth && errors.dateOfBirth.message
                        }
                        FormHelperTextProps={{
                          sx: {
                            fontSize: "12px",
                            position: "absolute",
                            top: "34px",
                            left: "-14px",
                          },
                        }}
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
            <Label>Gender</Label>
            <TextField
              id="gender"
              disabled
              data-testid="gender"
              variant="outlined"
              name="gender"
              defaultValue={
                adhaarInfo.gender
                  ? capitalizeString(genderDataMaping[adhaarInfo.gender])
                  : "Male"
              }
              sx={{ width: 290, marginBottom: "20px" }}
              InputProps={{
                sx: { height: 37, background: "#FFFFFF" },
              }}
              {...register("gender")}
              FormHelperTextProps={{
                sx: {
                  fontSize: "12px",
                  position: "absolute",
                  top: "34px",
                  left: "-14px",
                },
              }}
            >
              {genderData.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
            <Label>Phone Number</Label>
            <TextField
              id="phone"
              data-testid="phone"
              disabled
              name="phone"
              variant="outlined"
              sx={{ width: 290, marginBottom: "20px" }}
              InputProps={{
                sx: { height: 37, background: "#FFFFFF" },
              }}
              {...register("phone")}
              defaultValue={userInfo.phone}
              value={userInfo.phone}
              FormHelperTextProps={{
                sx: {
                  fontSize: "12px",
                  position: "absolute",
                  top: "34px",
                  left: "-14px",
                },
              }}
            />
            <Label>ABHA Address</Label>
            <TextField
              data-testid="healthId"
              disabled
              id="healthId"
              name="healthId"
              variant="outlined"
              sx={{ width: 290, marginBottom: "20px" }}
              InputProps={{
                sx: { height: 37, background: "#fff" },
              }}
              {...register("healthId")}
              defaultValue={adhaarInfo.healthId ? adhaarInfo.healthId : null}
              FormHelperTextProps={{
                sx: {
                  fontSize: "12px",
                  position: "absolute",
                  top: "34px",
                  left: "-14px",
                },
              }}
            />
            <Label>ABHA Number</Label>
            <TextField
              data-testid="healthIdNumber"
              disabled
              id="healthIdNumber"
              name="healthIdNumber"
              variant="outlined"
              sx={{ width: 290, marginBottom: "20px" }}
              InputProps={{
                sx: { height: 37, background: "#fff" },
              }}
              {...register("healthIdNumber")}
              defaultValue={
                adhaarInfo.healthIdNumber ? adhaarInfo.healthIdNumber : null
              }
              FormHelperTextProps={{
                sx: {
                  fontSize: "12px",
                  position: "absolute",
                  top: "34px",
                  left: "-14px",
                },
              }}
            />
            <Label>Pincode</Label>
            <TextField
              data-testid="pincode"
              id="pincode"
              name="pincode"
              variant="outlined"
              sx={{ width: 290, marginBottom: "20px" }}
              InputProps={{
                sx: { height: 37, background: "#fff" },
              }}
              {...register("pincode")}
              error={errors.pincode ? true : false}
              helperText={errors.pincode?.message}
              defaultValue={adhaarInfo.pincode ? adhaarInfo.pincode : null}
              FormHelperTextProps={{
                sx: {
                  fontSize: "12px",
                  position: "absolute",
                  top: "34px",
                  left: "-14px",
                },
              }}
            />
            <Label>State</Label>
            <TextField
              data-testid="stateName"
              id="stateName"
              name="stateName"
              variant="outlined"
              sx={{ width: 290, marginBottom: "20px" }}
              InputProps={{
                sx: { height: 37, background: "#fff" },
              }}
              {...register("stateName")}
              defaultValue={adhaarInfo.stateName ? adhaarInfo.stateName : ""}
              error={errors.stateName ? true : false}
              helperText={errors.stateName?.message}
              FormHelperTextProps={{
                sx: {
                  fontSize: "12px",
                  position: "absolute",
                  top: "34px",
                  left: "-14px",
                },
              }}
            />
            <Label>Address</Label>
            <TextField
              data-testid="address"
              id="relation"
              name="address"
              variant="outlined"
              sx={{ width: 290, marginBottom: "20px" }}
              InputProps={{
                sx: { height: 37, background: "#fff" },
              }}
              {...register("address")}
              defaultValue={adhaarInfo.address ? adhaarInfo.address : ""}
              error={errors.address ? true : false}
              helperText={errors.address?.message}
              FormHelperTextProps={{
                sx: {
                  fontSize: "12px",
                  position: "absolute",
                  top: "34px",
                  left: "-14px",
                },
              }}
            />
            {error && (
              <Text
                position="relative"
                top="0"
                size="12px"
                color="#BCBCBC"
                line="16px"
              >
                {errorMessage ? errorMessage : "Something went wrong"}
              </Text>
            )}
            <Button
              style={{ margin: "20px 0" }}
              width="290px"
              position="relative"
              top="0"
              onClick={handleSubmit(onSubmit)}
            >
              Verify
            </Button>
          </FormControl>
        </FormContent>
      )}
    </>
  );
};
export default ProfileEdit;
