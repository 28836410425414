import apiEndpoint from "../../Services/api";
import { post } from "../../Services/apiService";

// verifies abhaID and calls OTP generation function

export const verifyABHA = async (data) => {
  // const data = { healthIdNumber: ABHAID };
  const response = await post(apiEndpoint.linkABHA, data)
    .then((response) => {
      if (response?.data?.healthId ) {
        return {
          txn_id: response.data.data.transactionId,
          healthId: response.data.healthId,
          healthIdNumber: response.data.healthIdNumber,
        };
      }
      return { txn_id: response.data.transactionId };
    })
    .catch((err) => {
      throw err;
    });
  return response;
};

export const getAuthMethodsABHA = async (data) => {
  const response = await post(apiEndpoint.fetchAuthModes, data)
    .then((response) => {
      if (response?.status === 200) {
        return response.data;
      } else {
        return [];
      }
    })
    .catch((err) => {
      throw err;
    });
  return response;
};

export const searchByHealthId = async (data) => {
  const response = await post(apiEndpoint.searchByHealthID, data)
    .then((response) => {
      if (response?.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });
  return response;
};
