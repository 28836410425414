import styled from "styled-components";

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
  span{
    font-size:22px; 
    font-weight:600; 
    color:#DE3534;
  }
`;
