import Header from "../UiComponent/screenUI/Header";
import MainSection from "../UiComponent/screenUI/MainSectionCmp";
import logo from "../Assets/AadhaarLogo.png";
import { Button, ButtonWrapper } from "../UiComponent/styledComponents/Button";
import { Logo } from "../UiComponent/styledComponents/Logo";
import { Otp } from "../UiComponent/styledComponents/Otp";
import { Text } from "../UiComponent/styledComponents/Text";
import { Alert } from "../UiComponent/styledComponents/Alert";
import { Time } from "../UiComponent/styledComponents/Time";
import OTP from "../UiComponent/otpcmp/OtpCmp";
import OtpTime from "../UiComponent/otpcmp/OtpTimer";
import { useNavigate } from "react-router-dom";
import apiEndpoint from "../Services/api";
import { useState } from "react";
import { useContext } from "react";
import { Details } from "../context/Context";
import LoaderCmp from "../UiComponent/loader/Loader";
import { otpGenerator } from "./Aadhaar";
import { post } from "../Services/apiService";
import {
  getSessionStorageItem,
  setSessionStorageItem,
  decrypt,
  encrypt,
} from "../util/Helper";

const AadhaarOtp = () => {
  const naviagte = useNavigate();
  const { parentDetails, userInfo } = useContext(Details);
  const [disable, setDisable] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const OTPResendHandler = async () => {
    setOtp("");
    setAlertMessage("");
    const transactionId = decrypt(getSessionStorageItem("txnId"));
    await post(apiEndpoint.regenerateAadhaarOtp, {
      txnId: transactionId,
    })
      .then((res) => {
        setSessionStorageItem("txnId", encrypt(res.data.txnId));
      })
      .catch((err) => {
        setAlertMessage(err);
      });
    // await otpGenerator({ aadhaar: aadhaarNumber, consentTaken: consent })
    //   .then((res) => {
    //     setSessionStorageItem("txnId", encrypt(res));
    //   })
    //   .catch((err) => {
    //     setAlertMessage(err);
    //   });
  };

  const otpHandle = (e) => {
    setOtp(e);
    setAlertMessage("");
    if (e.length !== 6) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };
  const handleOtp = async () => {
    setLoading(true);
    const data = {
      otp: otp,
      response_txn_id: decrypt(getSessionStorageItem("txnId")),
      content_id: "1",
      given_by_id: parentDetails.azureId,
      given_by_name: userInfo.name ? userInfo.name : parentDetails.name,
    };
    await post(apiEndpoint.verifyAadhaarOtp, data)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          naviagte("/Details");
        }
      })
      .catch((err) => {
        setLoading(false);
        let message = "";
        if (err?.response?.data?.details) {
          if (err.response.data.details[0].code === "HIS-2022") {
            setAlertMessage("Invalid OTP");
          } else if (err.response.data.details[0].code === "HIS-1041") {
            setAlertMessage(
              "Reached maximum attempts, try again after sometime"
            );
          } else {
            setAlertMessage(err.response.data.details[0].message);
          }
          return;
        }
        if (err?.response?.data?.error?.message) {
          if (
            err.response.data.error.message.includes(" not allowed to be empty")
          ) {
            message = "Some error occurred, please try again after sometime.";
          } else {
            message = err.response.data.error.message;
          }
        } else {
          if (
            err?.response?.data?.message.includes(" not allowed to be empty")
          ) {
            message = "Some error occurred, please try again after sometime.";
          } else {
            message = err.response.data.message;
          }
        }
        setAlertMessage(message);
      });
  };
  return (
    <>
      {loading ? <LoaderCmp /> : null}
      <Header title={"Enter OTP"} location="/" />
      <MainSection scroll="auto">
        <Logo src={logo} alt="Adhaar Logo" />
        <Text top="263px">
          Enter OTP recieved on mobile number registered with Aadhaar
        </Text>
        <Otp top="342px">
          <OTP handleChange={otpHandle} value={otp} />
        </Otp>

        {alertMessage && <Alert data-testid="message">{alertMessage}</Alert>}
        <Time>
          <OtpTime OTPResendHandler={OTPResendHandler} />
        </Time>

        <ButtonWrapper>
          <Button disabled={disable} onClick={handleOtp}>
            Submit
          </Button>
        </ButtonWrapper>
      </MainSection>
    </>
  );
};
export default AadhaarOtp;
