import React, { memo, useEffect } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

import { loginRequest } from "./authConfig";
import LoaderCmp from "../UiComponent/loader/Loader";

const Login = memo(() => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }else{
      instance.loginRedirect(loginRequest);
    }
  }, [isAuthenticated, navigate]);

return <LoaderCmp data-testid="loader" />;
});

export default Login;
