import { Show } from "../../UiComponent/styledComponents/Show";
import { Text } from "../../UiComponent/styledComponents/Text";
import NewSuccessLogo from "../../Assets/NewSuccessLogo.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import { Details } from "../../context/Context";

const SuccessABHANumber = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/AbhaId");
    }, 2000);
  });
  return (
    <>
      <Show>
        <img
          style={{
            width: "250px",
            height: "200px",
            top: "200px",
          }}
          src={NewSuccessLogo}
          alt="Success Logo"
        />
        <Text line="30px" top="460px" size="24px" color="#313131" weight="700">
          Great! Your ABHA Number has been generated successfully!
        </Text>
        {/* <Text top="532px">
          You can now use Svaas for your healthcare records management
        </Text> */}
      </Show>
    </>
  );
};
export default SuccessABHANumber;
