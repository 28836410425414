import styled from "styled-components";

export const Card = styled.div`
  background-color: white;
  // position:relative;
  width: 100%;
  height: ${(props) => props.height || "calc(100vh - 78px)" };
  display: flex;
  top: ${(props) => props.top};
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  border-radius: 30px 30px 0 0;
  gap:24px;
  position: ${(props) => props.position || "inherit"};
  overflow-y:${(props) => props.overflow || "hidden"}
`;