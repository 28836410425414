import { useEffect, useState } from "react";
import { Timer } from "../styledComponents/Timer";
const OtpTime = (props) => {
  const [sec, setsec] = useState(59);
  const [min, setmin] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (sec >= 1) {
        setsec(sec - 1);
      } else if (min > 0) {
        setsec(59);
        setmin(min - 1);
      }
    }, 1000);
  }, [sec, min]);

  const OTPResendHandler = () => {
    setsec(59);
    setmin(0);
    props.OTPResendHandler();
  };
  return (
    <>
      <Timer top="376px">
        {
          <p style={{ color: "#CFCFCF" }}>
            Didn’t receive the OTP?{" "}
            {sec === 0 && min === 0 ? null : (
              <span style={{ color: "#494E9D" }}>
                {min.toString().padStart(2, "0")}:
                {sec.toString().padStart(2, "0")}
              </span>
            )}
            {
              <span
                style={{
                  fontWeight: "600",
                  color: sec === 0 && min === 0 ? "#494E9D" : "#ccc",
                  pointerEvents: sec === 0 && min === 0 ? "auto" : "none",
                }}
                onClick={OTPResendHandler}
                data-testid="resendHandler"
              >
                &nbsp;&nbsp;Resend
              </span>
            }
          </p>
        }
      </Timer>
    </>
  );
};
export default OtpTime;
