import React from "react";
import styled from "styled-components";
import Header from "../../UiComponent/screenUI/Header";
import { Text } from "../../UiComponent/styledComponents/Text";

function HeaderSection() {
  return (
    <>
      <Header height="20px" location={"navigateToParent"} title={"ENTER ABHA"} ></Header>
      <Container>
        <Text top="109px">What is ABHA?</Text>
        <Text top="155px" size="14px">
          ABHA enables access to all your medical records all in one place with
          just a tap of a button.
        </Text>
        <Text top="200px" size="14px">
          Create or Share your ABHA with Svaas now
        </Text>
      </Container>
    </>
  );
}

export default HeaderSection;

const Container =styled.div`
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;`