// export const relationData=[
//     {
//         label:"Self",
//         value:"Self"
//     },
//     {
//         label:"Son",
//         value:"Son"
//     },
//     {
//         label:"Father",
//         value:"Father"
//     },
//     {
//         label:"Mother",
//         value:"Mother"
//     },
//     {
//         label:"Daughter",
//         value:"Daughter"
//     },
//     {
//         label:"Sister",
//         value:"Sister"
//     },
//     {
//         label:"Spouse",
//         value:"Spouse"
//     },

// ]
export const genderData = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Others",
    value: "Others",
  },
];

export const genderDataMaping = {
  M: "male",
  F: "female",
};
