import styled from "styled-components";

export const MainContainer = styled.div`
  overflow: auto;
  height:calc( 100vh - 78px)
`;

export const ABHAButton = styled.button`
  border: none;
  border-radius: ${(props) => props.borderRadius || ""};
  width: ${(props) => props.width || "375px"};
  max-width: 375px;
  height: ${(props) => props.height || "59px"};;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #494e9d;
  color: white;
  border-radius: 4px;
  box-shadow: 8px 0px 15px;
  font-size: 15px;
  &:disabled {
    background-color: #ede7f6;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: "#494E9D";
  padding: 40px 25px;
  gap:12px;
  img {
    height: 40%;
    width: 60%;
  }
`;
export const TopSection = styled.div`
  background-color: #f8f8ff;
  border: 1px solid #e2e2fe;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: end;
  position: relative;
  height: 100px;
  width: 75%;
  max-width:324px;
`;

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;

export const ProfileImgContainer = styled.div`
  display: flex;
  height: 74px;
  width: 74px;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  img {
    height: 100%;
    width: 100%;
    display: flex;
    border-radius: 50%;
  }
`;

export const ProfileNameContainer = styled.div`
  color: "#5A5A5A";
  display: flex;
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
`;

export const AgeContainer = styled.div`
  color: "#5A5A5A";
  display: flex;
  line-height: 16px;
  font-size: 12px;
  font-weight: 300;
`;

export const AbhaCardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  img{
    max-width:324px;
    max-height:324px;
  }
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  max-width:324px;
  padding-top: 30px;
`;

export const HealthIdContainer = styled.div`
  color: "#5A5A5A";
  display: flex;
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
`;

export const HealthNumberContainer = styled.div`
  color: "#5A5A5A";
  display: flex;
  line-height: 16px;
  font-size: 12px;
  font-weight: 300;
`;

export const PrescriptionPreview = styled.div`
  position: fixed;
  z-index: 52;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: scroll;
  top: 0;
  bottom: 0;
`;
export const PrescriptionPreviewHeader = styled.div`
  height: 46px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.6px solid #e5e3e3;
`;
export const PreviewIcon = styled.div`
  display: flex;
  cursor: pointer;
  svg {
    path {
      stroke: #313131;
    }
  }
`;
export const PreviewName = styled.div`
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #494e9d;
  width: 90%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PreviewFooter = styled.div`
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 0.6px solid #e5e3e3;
`;

export const PrescriptionPreviewPlace = styled.div`
  width: 100%;
  height: 100%;
`;
export const PreviewText = styled.div`
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #313131;
  cursor: pointer;
`;
export const ButtonConainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  padding-top: 10px;
  justify-content: center;
  width: 100%;
  align-items: center;
  button {
    height: 40px;
  }
`;
export const ItemPreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
  img {
    height: 70%;
  }
`;

export const DownloadButton = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  border-radius: 4px;
  height: 40px;
  align-items: center;
  width: 70%;
  max-width:324px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #494E9D;
`;
