import styled from "styled-components";

export const CardBox = styled.div`
background-color: #f8f8ff;
width: 305px;
height: 142px;
margin-top: 60px;
border: 1px solid #e2e2fe;
border-radius: 10px;
display: flex;
justify-content: center;
gap:12px;
flex-direction:column;
align-items:center;
`;

export const TextContainer = styled.div`
`

