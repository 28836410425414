import styled from "styled-components";

export const Span = styled.span`
top: 62px;
left: 0px;
font-family: "Nunito Sans";
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
color: #bcbcbc;
`;