export const OTP_OPTIONS = {
  MOBILE_OTP: {
    label: "ABHA Linked Mobile Number",
    value: "MOBILE_OTP",
  },
  AADHAAR_OTP: {
    label: "Aadhar Linked Mobile Number",
    value: "AADHAAR_OTP",
  },
  DEMOGRAPHICS: {
    label: "Add your demographic details",
    value: "DEMOGRAPHICS",
  },
  PASSWORD: {
    label: "Enter the Password",
    value: "PASSWORD",
  },
  DIRECT: {
    label: "Direct",
    value: "DIRECT",
  },
};
