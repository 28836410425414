import { Show } from "../../UiComponent/styledComponents/Show";
import { Text } from "../../UiComponent/styledComponents/Text";
import NewSuccessLogo from "../../Assets/NewSuccessLogo.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import { Details } from "../../context/Context";

const Success = () => {
  const navigate = useNavigate();
  const { parentDetails } = useContext(Details);
  const location = useLocation().state.flow;
  const profile = useLocation().state.profile;
  useEffect(() => {
    setTimeout(() => {
      location === "linked"
        ? navigate("/AbhaCard")
        : profile
        ? navigate("/profile")
        : navigate("/AbhaQrCard");
    }, 2000);
  });
  return (
    <>
      <Show>
        <img
          style={{
            width: "250px",
            height: "200px",
            top: "200px",
          }}
          src={NewSuccessLogo}
          alt="Success Logo"
        />
        <Text line="30px" top="460px" size="24px" color="#313131" weight="700">
          {location === "linked"
            ? `Great! Your ABHA has been verified successfully!`
            : `Great! Your ABHA address has been ${location} successfully!`}
        </Text>
        {/* <Text top="532px">
          You can now use Svaas for your healthcare records management
        </Text> */}
      </Show>
    </>
  );
};
export default Success;
