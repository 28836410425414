import styled from "styled-components";

export const Text = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: ${(props) => props.weight || 400};
  font-size: ${(props) => props.size || "16px"};
  color: ${(props) => props.color || "#5A5A5A"};
  right: ${(props) => props.right || ""};
  left: ${(props) => props.left || ""};
  width: ${(props) => props.width || "309px"};
  // position: ${(props) => props.position || "absolute"};
  text-align: center;
  line-height: ${(props) => props.line || "19px"};
  top: ${(props) => props.top};
  align-items: center;
`;
export const DownloadLink = styled.a`
  display: flex;
  width: 250px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
`;
