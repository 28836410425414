import styled from "styled-components";

export const Skip = styled.p`
position: absolute;
left: 84%;
right: 8.53%;
top: 7.88%;
bottom: 89.9%;
font-family: "Nunito Sans";
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 18px;
text-align: right;
color: #ffffff;
cursor: pointer;
`;