import { QrCode } from "../../UiComponent/styledComponents/QrCode";
import { Text } from "../../UiComponent/styledComponents/Text";
import Header from "../../UiComponent/screenUI/Header";
import MainSection from "../../UiComponent/screenUI/MainSectionCmp";
import { UserDetailsCard } from "./UserDetailsCard";
import { useEffect, useState, useContext, forwardRef } from "react";
import LoaderCmp from "../../UiComponent/loader/Loader";
import apiEndpoint from "../../Services/api";
import { Details } from "../../context/Context";
import { get } from "../../Services/apiService";
import { Button } from "../../UiComponent/styledComponents/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import DialogContent from "@mui/material/DialogContent";
import { ABHACard } from "../../UiComponent/styledComponents/ABHACard";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const imageList = {
  "Profile photo": "profile_photo",
  "ABHA QRcode": "qr_code",
  "ABHA card": "abha_card",
};
const imageTypeList = {
  "Profile photo": "profile_photo_type",
  "ABHA QRcode": "qr_code_type",
  "ABHA card": "abha_card_type",
};

const AbhaCardNew = () => {
  const [showCard, setShowCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const { parentDetails } = useContext(Details);
  const [age, setAge] = useState(0);
  const [data, setData] = useState({
    full_name: "",
    birthDate: "",
    gender: "",
    profile_photo: "",
    profile_photo_type: "",
    qr_code: "",
    qr_code_type: "",
    abha_card: "",
    abha_card_type: "",
    health_id: "",
    health_number: "",
  });

  useEffect(() => {
    getUserData();
  }, []);
  const getUserData = async () => {
    setLoading(true);
    get(`${apiEndpoint.getABHAUser}?id=${parentDetails.azureId}`)
      .then((res) => {
        let profile_photo = "";
        let profile_photo_type = "";
        let qr_code = "";
        let qr_code_type = "";
        let abha_card = "";
        let abha_card_type = "";
        res?.data?.photo?.forEach((photo) => {
          if (photo.title === "Profile photo") {
            profile_photo = photo.data;
            profile_photo_type = photo.contentType;
          } else if (photo.title === "ABHA QRcode") {
            qr_code = photo.data;
            qr_code_type = photo.contentType;
          } else {
            abha_card = photo.data;
            abha_card_type = photo.contentType;
          }
        });
        setAge(calculateAge(res.data.birthDate));
        setData({
          ...res.data,
          profile_photo,
          profile_photo_type,
          qr_code,
          qr_code_type,
          abha_card,
          abha_card_type,
        });
        setLoading(false);
        setShowCard(true);
        setIsError(false);
      })
      .catch((err) => {
        setLoading(false);
        setIsError(true);
        setError("No data found");
      });
  };

  const calculateAge = (dob) => {
    const dobDate = new Date(dob);
    const currentDate = new Date();
    const yearsDiff = currentDate.getFullYear() - dobDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const dobMonth = dobDate.getMonth();
    if (
      currentMonth < dobMonth ||
      (currentMonth === dobMonth && currentDate.getDate() < dobDate.getDate())
    ) {
      return yearsDiff - 1;
    }
    return yearsDiff;
  };

  function convertBase64ToSrc(base64Data, mimeType) {
    if (!base64Data) return null;
    const dataUrl = `data:${mimeType};base64,${base64Data}`;
    return dataUrl;
  }

  return (
    <>
      {loading ? <LoaderCmp data-testid="loader" /> : null}
      <Header
        title={"My Profile"}
        location={showCard ? "navigateToParent" : "/"}
      />
      {showCard ? (
        <MainSection scroll="auto">
          <UserDetailsCard
            ProfileImg={convertBase64ToSrc(
              data.profile_photo,
              data.profile_photo_type
            )}
            name={data.full_name}
            gender={data.gender}
            age={age}
          />
          <div>
          <Text top="340px" weight="700" line="24px">
            {data.health_id}
          </Text>
          <Text top="379px" size="12px" weight="300" line="16px">
            {data.health_number}
          </Text>
          </div>
          {/* {data.qr_code && data.qr_code_type && (
            <QrCode
              src={convertBase64ToSrc(data.qr_code, data.qr_code_type)}
              alt="Qr code"
            />
          )} */}
        </MainSection>
      ) : isError ? (
        <Text color="red">{error}</Text>
      ) : null}
    </>
  );
};
export default AbhaCardNew;
