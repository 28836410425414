import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { ModalCloseIcon } from "../../../UiComponent/styledComponents/ModalCloseIcon";
import { CloseIcon } from "../../../UiComponent/styledComponents/CloseIcon";
import Slide from "@mui/material/Slide";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Button } from "../../../UiComponent/styledComponents/Button";
import { getAuthMethodsABHA } from "../LinkAPI";
import { OTP_OPTIONS } from "../../../util/constants";
import LoaderCmp from "../../../UiComponent/loader/Loader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OtpSelectionPopup({
  isOpen,
  handleClose,
  onSuccess,
  value,
  handleChange,
  setError,
  info,
  isRegistered,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(["AADHAAR_OTP", "MOBILE_OTP"]);

  useEffect(() => {
    if (isOpen && isRegistered) {
      setIsLoading(true);
      getAuthMethodsABHA(info)
        .then((res) => {
          setIsLoading(false);
          if (res.length === 0) {
            setError("Something went wrong! Please try again");
            handleClose();
          } else {
            setIsLoading(false);
            setData(res);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setError("Invalid details. Please try again");
          handleClose();
        });
    }
  }, [isOpen]);

  const theme = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "20px 20px 0 0",
            minWidth: "375px",
            overflowX: "hidden",
            top: "35px",
            paddingBottom: "30px",
          },
        },
      },
    },
  });

  return (
    <>
      {isLoading ? <LoaderCmp /> : null}
      <ThemeProvider theme={theme}>
        <Dialog
          open={isOpen}
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            mt: "200px",
            top: "16%",
          }}
          PaperProps={{ style: { margin: "0px" } }}
          data-testid="modal-container"
        >
          <DialogActions
            sx={{
              height: "10px",
            }}
          >
            <CloseIcon>
              <ModalCloseIcon data-testid="modal-close" onClick={handleClose} />
            </CloseIcon>
          </DialogActions>
          <DialogTitle> Select Preferred OTP Channel </DialogTitle>
          <DialogContent>
            <div>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  {data.map((item) => (
                    <>
                      {item !== "DEMOGRAPHICS" &&
                        item !== "DIRECT" &&
                        item !== "PASSWORD" && (
                          <FormControlLabel
                            key={OTP_OPTIONS[item].value}
                            value={OTP_OPTIONS[item].value}
                            control={<Radio />}
                            label={OTP_OPTIONS[item].label}
                          />
                        )}
                    </>
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button left="calc(50% - 375px/2)" top="220px" onClick={onSuccess}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
}
