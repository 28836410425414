import styled from "styled-components";

export const ArrowRight = styled.div`
background-color: white;
width: 52px;
height: 52px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
position: absolute;
color: blue;
top: 89.9%;
right: 8.53%;
font-size: 19px;
cursor:pointer;
`;