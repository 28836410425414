import { useContext, useState } from "react";
import Header from "../../../UiComponent/screenUI/Header";
import {
  Button,
  ButtonWrapper,
} from "../../../UiComponent/styledComponents/Button";
import { Links } from "../../../UiComponent/styledComponents/Links";
import { LinkText } from "../../../UiComponent/styledComponents/LinkText";
import { useNavigate } from "react-router-dom";
import { DownloadLink, Text } from "../../../UiComponent/styledComponents/Text";
import { Details } from "../../../context/Context";
import { Container } from "../../../UiComponent/styledComponents/Containers";

import apiEndpoint from "../../../Services/api";
import { post } from "../../../Services/apiService";
import HeaderSection from "../HeaderSection";
import Main from "../Main";
import MainSection from "../../../UiComponent/screenUI/MainSectionCmp";
import { ABHAInputCard } from "../../../UiComponent/styledComponents/ABHAInputCard";
import { ProfileBackGround } from "../../../UiComponent/styledComponents/ProfileBackGround";
import { HR } from "../../../UiComponent/styledComponents/HR";
import { Input } from "../../../UiComponent/styledComponents/Input";
import LoaderCmp from "../../../UiComponent/loader/Loader";
import { fetchSharedProfileData } from "./FetchedSharedProfileAPI";
import AbhaQR from "../../../Assets/AbhaQR.jpg";
import { FlexContainer } from "../../../UiComponent/styledComponents/FlexContainer";

const FetchSharedProfile = () => {
  const [loading, setLoading] = useState(false);
  const [initalState, setInitialState] = useState(true);
  const [tokenNumber, settokenNumber] = useState("");
  const [data, setData] = useState("");
  const navigate = useNavigate();

  const { ABHANumber, setABHANumber, setABHAID, ABHAID, parentDetails } =
    useContext(Details);
  const [Message, setMessage] = useState("");

  const FetchProfileChangeHandler = (event) => {
    var token = event.target.value;
    if (Message) {
      setMessage("");
    }
    if (isNaN(token)) {
      setMessage("Enter valid Token number");
    }
    settokenNumber(token);
  };

  const submitProfileHandler = async () => {
    console.log("Profile getting Fetched");
    setLoading(true);
    try {
      // const res = await fetchSharedProfileData(tokenNumber, parentDetails.azureId);
      const res = await fetchSharedProfileData(
        tokenNumber,
        parentDetails.azureId,
        parentDetails.partnerId
      );

      if (res && res.status === 200) {
        setLoading(false);

        navigate("/Success", { state: { flow: "linked" } });
      } else {
        setLoading(false);
        setMessage("Please enter a valid token");
      }
    } catch (err) {
      setLoading(false);
      setMessage("Some error occurred.");
      if (err?.response?.data?.details?.[0]) {
        if (err.response.data.details[0].code === "HIS-1008") {
          setMessage(`Invalid ABHA ${initalState ? "number" : "id"}`);
        } else {
          setMessage(err.response.data.details[0].message);
        }
      } else if (err?.response?.data?.error?.message) {
        setMessage(err.response.data.error.message);
      } else if (err?.response?.data) {
        setMessage(err.response.data);
      }
    }
  };

  return (
    <>
      {loading ? <LoaderCmp /> : null}
      <HeaderSection />
      <MainSection  position="absolute" scroll="auto">
        <Main id={tokenNumber} />
        <ABHAInputCard>
          <Container>
            <ProfileBackGround>
              <Text right="-132px" size="30px" top="-15px" color="#43BC73">
                {parentDetails?.name ? parentDetails.name[0].toUpperCase() : ""}
              </Text>
            </ProfileBackGround>
            <FlexContainer className="container">
              <Text color="313131" className="noMargin" width="fit-content" weight="700" size="14px">
                {`Fetch Profile  from PHR`}
              </Text>
              <Text
                top="23px"
                size="14px"
                left="60px"
                weight="200"
                width="250px"
                style={{ textAlign: "left" }}
              >
                Link your account by sharing profile
              </Text>
              {/* <Text
          onClick={() => navigate("/scan")}
          top="43px"
          size="14px"
          left="60px"
          weight="200"
          width="250px"
          style={{ textAlign: "left", cursor: "pointer" }}
        >
          (SVAAS-HIP QR Code)
        </Text> */}
              <DownloadLink href={AbhaQR} download>
                SVAAS-HIP QR Code
              </DownloadLink>
            </FlexContainer>
          </Container>
          <HR />
          <FlexContainer>
            <Input
              autoFocus={true}
              inputMode={"numeric"}
              maxLength={8}
              placeholder={"Enter your token number"}
              // onChange={initalState ? ABHANoChangeHandler : ABHAIDChangeHandler} //FetchProfileChangeHandler
              onChange={FetchProfileChangeHandler}
              style={Message ? { borderColor: "red" } : null}
              value={tokenNumber}
            />
          </FlexContainer>
        </ABHAInputCard>

        {Message && (
          <Text color="red" top="405px">
            {Message}
          </Text>
        )}

        <div
          style={{
            width: "343px",
            height: "16px",
            top: "445px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LinkText
            onClick={() => {
              setMessage("");
              navigate("/link");
              setInitialState((prevState) => {
                return !prevState;
              });
            }}
          >
            {`Use ABHA  Address / Number`}
          </LinkText>
        </div>
        <div
          style={{
            width: "343px",
            height: "16px",
            top: "490px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LinkText
            onClick={() => {
              navigate("/scan");
            }}
          >
            Scan ABHA QR Code
          </LinkText>
        </div>

        <ButtonWrapper>
          <Button
            left="calc(50% - 375px/2)"
            top="calc(50% - 59px/2 + 254px)"
            onClick={submitProfileHandler}
            disabled={tokenNumber.length < 1 || Message}
          >
            Proceed
          </Button>
        </ButtonWrapper>
      </MainSection>
    </>
  );
};

export default FetchSharedProfile;
