import styled from "styled-components";

export const Label = styled.label`
margin-bottom: 5px;
margin-top: 5px;
font-weight: 400;
font-size: 13px;
line-height: 16px;
color: #5a5a5a;
max-width: 290px;
`;