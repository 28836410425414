import React, { useContext } from "react";
import { ABHALogo } from "../../UiComponent/styledComponents/ABHALogo";
import { Text } from "../../UiComponent/styledComponents/Text";
import AbhaLogo from "../../Assets/Group 1.png";
import { Details } from "../../context/Context";
import { FlexContainer } from "../../UiComponent/styledComponents/FlexContainer";
import { capitalize } from "../../util/Capitalize";

function Main({ id }) {
  const { userInfo } = useContext(Details);
  return (
    <FlexContainer>
      <ABHALogo src={AbhaLogo} alt="abha logo" />
      <Text top="147px" size="22px" weight="700" color="black">
        Hi !
      </Text>
      <span >
        {userInfo?.name && userInfo.name !== ""
          ? " " + userInfo.name + " "
          : " "}
      </span>
      <Text top="207px">{`Link your ABHA with SVAAS`}</Text>
    </FlexContainer>
  );
}

export default Main;
