import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";



function OTPDialog(props) {
    const [value, setValue] = React.useState('MOBILE_OTP');

    const handleChange = (event) => {
        setValue(event.target.value);
        console.log(event.target.value)
    };

    const divStyle = {
        display: "flex",
        felxDirection: "row",
        position: "absolute",
        right: "0px",
        bottom: "0px",
        // padding: "1rem",
    };

    console.log('Dialog props:', props);

    return (
        <><div className="dialog">
            <h2>{props.title}</h2>
            <p>{props.message}</p>
            <button style={{ display: 'flex', justifyContent: 'right' }} onClick={props.onClose}>Close</button>
            {/* <Dialog onClose={handleClose} open={openDialog}></Dialog> */}
            <DialogTitle> Select Preferred OTP Method </DialogTitle>
        </div><div>
                <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">Method</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="AADHAAR_OTP" control={<Radio />} label="Aadhar" />
                        <FormControlLabel value="MOBILE_OTP" control={<Radio />} label="Mobile" />
                    </RadioGroup>
                </FormControl>
            </div></>

    );
}

export default OTPDialog;
